import {
  Label,
  Input,
} from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import styles from "./DateTimeInput.module.scss"
import { usePolyglot } from "src/contexts"
import type { EditableUserEntry } from "src/types"

const timeInputFormat = "HH:mm"

interface Props {
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry?: (userEntry: EditableUserEntry) => void;
}

export function TimeInput(props: Props) {
  const {
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const polyglot = usePolyglot()

  const timeValue = dayjs(currentUserEntry.started_at).format(timeInputFormat)

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const [
        oldHours,
        oldMinutes,
      ] = timeValue.split(":")
      const timeString = e.target.value
      const [
        hours,
        minutes,
      ] = timeString.split(":")
      let date = dayjs(currentUserEntry.started_at)
      date = date.hour(Number(hours || oldHours))
      date = date.minute(Number(minutes || oldMinutes))

      setCurrentUserEntry?.({
        ...currentUserEntry,
        started_at: date.toISOString(),
      })
    },
    [
      currentUserEntry,
      setCurrentUserEntry,
      timeValue,
    ],
  )

  return (
    <div>
      <Label
        htmlFor="time"
        className={styles.label}
        sizePreset="small"
      >
        {polyglot.t("components.entry_modal.datetime.time_input_label")}
      </Label>
      <Input
        type="time"
        id="time"
        value={timeValue}
        onChange={onChange}
        className={styles.input}
      />
    </div>
  )
}
