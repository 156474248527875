import {
  Label,
  InfoButton,
  Input,
} from "@tandem-mobile/react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"
import styles from "./Account.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useAccountProperty,
} from "src/hooks"
import type {
  Account,
  Settings,
} from "src/types"

const polyglotPrefix = "pages.settings.account.nickname."
const nicknameId = "nickname-id"

interface Props {
  updateSettings: (settings: Partial<Settings>) => void;
}

export function Nickname(props: Props) {
  const { updateSettings } = props

  const polyglot = usePolyglot()
  const updatedAt = useAccountProperty(
    "updated_at",
    undefined,
  )
  const currentEmail = useAccountProperty(
    "email",
    "",
  )
  const currentNickname = useAccountProperty(
    "nickname",
    null,
  )

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.value,
    [],
  )

  const onValueChange = useCallback(
    (nickname: Account["nickname"]) => {
      updateSettings({
        nickname: nickname ?? undefined,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentNickname,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <div className={styles.inputContainer}>
      <Label
        className={styles.label}
        htmlFor={nicknameId}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Input
        className={styles.nicknameInput}
        id={nicknameId}
        value={value ?? currentEmail.split("@")[0]}
        onChange={onChange}
        autoComplete="nickname"
      />
    </div>
  )
}
