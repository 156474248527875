import {
  Modal,
  ModalBody,
  ModalCloseX,
  ModalContent,
  ModalHeading,
} from "@tandem-mobile/react-components"
import type { ReactNode } from "react"
import {
  useEffect,
  useState,
  useMemo,
} from "react"

import { FoodSearchButton } from "./FoodSearchButton"
import { FoodSearchContext } from "./FoodSearchContext"
import { FoodSearchInput } from "./FoodSearchInput"
import styles from "./FoodSearchModal.module.scss"
import { FoodSearchResults } from "./FoodSearchResults"
import { ActivityIcon } from "src/components/Activity"
import { useSearchFoods } from "src/hooks"
import type {
  EditableSubEntry,
  FoodActivity,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  activity: FoodActivity;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Food>>) => void;
  children?: ReactNode;
}

/*
 * this component renders the search modal where the user can pick a food
 * search result and select it for their food subentry
 *
 * all children of this component can open the search modal and change the
 * search term
 *
 * TODO
 * - special state for when there are no results (designs pending)
 */
export function FoodSearchModal(props: Props) {
  const {
    updateSubEntry,
    activity,
  } = props

  const [
    textValue,
    setTextValue,
  ] = useState<string>("")

  const [
    searchValue,
    setSearchValue,
  ] = useState<string>("")

  const [
    currentFoodIndex,
    setCurrentFoodIndex,
  ] = useState<number>(0)

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  // search for the activity title if there is one when the modal is opened
  useEffect(
    () => {
      if (isOpen) {
        setTextValue(activity.title ?? "")
        setSearchValue(activity.title ?? "")
      }
    },
    [
      activity.title,
      isOpen,
      setTextValue,
      setSearchValue,
    ],
  )

  // context value that allows all children to edit the search term
  const contextValue = useMemo(
    () => ({
      setSearchValue,
    }),
    [setSearchValue],
  )

  // get the serach results for the current search value
  const searchResults = useSearchFoods(searchValue)

  // when new search results are returned for a new search expression
  // reset the food index to 0
  useEffect(
    () => {
      if (searchResults.searchExpression === searchValue) {
        setCurrentFoodIndex(0)
      }
    },
    [
      searchResults.searchExpression,
      searchValue,
      setCurrentFoodIndex,
    ],
  )

  return (
    <FoodSearchContext.Provider value={contextValue}>
      <Modal open={isOpen}
        onOpenChange={setIsOpen}
      >
        {props.children}
        <ModalContent>
          <ModalHeading>
            <ActivityIcon
              activityType={ActivityType.Food}
              className={styles.activityIcon}
            />
            <ModalCloseX />
          </ModalHeading>
          <ModalBody className={styles.modalBody}>
            <FoodSearchInput
              textValue={textValue}
              setTextValue={setTextValue}
              setSearchValue={setSearchValue}
            />
            <div className={styles.searchContent}>
              <FoodSearchButton
                textValue={textValue}
                hidden={!!searchResults.foods.length}
                setSearchValue={setSearchValue}
              />
              <FoodSearchResults
                activity={activity}
                currentFoodIndex={currentFoodIndex}
                searchResults={searchResults}
                searchValue={searchValue}
                setCurrentFoodIndex={setCurrentFoodIndex}
                updateSubEntry={updateSubEntry}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </FoodSearchContext.Provider>
  )
}
