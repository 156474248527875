import {
  Dialog,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  DialogContent,
  DialogHeading,
  DialogTrigger,
  PopoverMenuItem,
  PopoverMenuItemIcon,
  TrashCanOutlineIcon,
  usePopoverContext,
  useToasterContext,
} from "@tandem-mobile/react-components"
import { useCallback } from "react"
import { useEntryModalPrivateContext } from "../EntryModalPrivateContext"
import styles from "./ContextMenu.module.scss"
import { usePolyglot } from "src/contexts"
import { RemoveSubEntrySuccessToast } from "src/toasts"

const polyglotPrefix = "components.entry_modal.sub_entry_context_menu."

export function DeleteSubEntry() {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const { removeCurrentSubEntry } = useEntryModalPrivateContext()
  const { setOpen: setPopoverOpen } = usePopoverContext()

  const deleteSubEntry = useCallback(
    () => {
      removeCurrentSubEntry()

      // TODO not sure if it is necessary to show a toast here
      showToast(
        "remove-sub-entry-success-toast",
        RemoveSubEntrySuccessToast,
      )

      setPopoverOpen(false)
    },
    [
      showToast,
      removeCurrentSubEntry,
      setPopoverOpen,
    ],
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <PopoverMenuItem className={styles.deleteButton}>
          <PopoverMenuItemIcon>
            <TrashCanOutlineIcon />
          </PopoverMenuItemIcon>
          {polyglot.t(`${polyglotPrefix}delete_cta`)}
        </PopoverMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}delete_dialog_header`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogButtons>
          <DialogButton
            stylePreset="destructive"
            onClick={deleteSubEntry}
          >
            {polyglot.t(`${polyglotPrefix}delete_dialog_confirm`)}
          </DialogButton>
          <DialogButton
            stylePreset="primary"
          >
            {polyglot.t(`${polyglotPrefix}delete_dialog_cancel`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
