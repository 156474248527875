import {
  CaretLeftIcon,
  HomeIcon,
  IconButtonLink,
  IconButtonLinkIcon,
} from "@tandem-mobile/react-components"
import {
  useRouterState,
  Link,
} from "@tanstack/react-router"
import { useMemo } from "react"
import styles from "./PageHeader.module.scss"
import {
  NavigationMenuTrigger,
  UserInitialButton,
} from "src/components"
import { usePolyglot } from "src/contexts"
import { useAccountProperty } from "src/hooks"
import type { RoutePath } from "src/types"

interface Props {
  backRoutePath?: RoutePath;
}

export function PageHeader(props: Props) {
  const { backRoutePath } = props
  const polyglot = usePolyglot()
  const currentPath = useRouterState({
    select: (s) => s.resolvedLocation.pathname,
  })

  const currentSegment = useMemo(
    () => {
      const pathArray = currentPath.split("/")
      return pathArray[pathArray.length - 1]
    },
    [currentPath],
  )

  const userName = useAccountProperty(
    "nickname",
    polyglot.t("components.page_header.default_user_name"),
  )

  const titleKey = `components.page_header.pages.${currentSegment}`

  return (
    <div className={styles.container}>
      {backRoutePath ? (
        <IconButtonLink
          className={styles.backButton}
          stylePreset="withoutBackground"
          asChild
        >
          <Link
            to={backRoutePath}
            params={{}}
          >
            <IconButtonLinkIcon>
              <CaretLeftIcon className={styles.backButtonIcon }/>
            </IconButtonLinkIcon>
          </Link>
        </IconButtonLink>
      ) : (
        <IconButtonLink
          className={styles.homeButton}
          stylePreset="withoutBackground"
          asChild
        >
          <Link to={"/home"}>
            <IconButtonLinkIcon>
              <HomeIcon className={styles.homeButtonIcon }/>
            </IconButtonLinkIcon>
          </Link>
        </IconButtonLink>
      )}
      <div className={styles.centerText}>
        <div className={styles.title} >
          {polyglot.has(titleKey) && polyglot.t(titleKey)}
        </div>
        <div className={styles.userName}>
          {userName}
        </div>
      </div>
      <NavigationMenuTrigger asChild>
        <UserInitialButton className={styles.menuButton} />
      </NavigationMenuTrigger>
    </div>
  )
}
