import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type { FoodActivity } from "src/types"
import { round } from "src/utils"

interface Props {
  activity: FoodActivity;
  updateActivity: (a: FoodActivity) => void;
}

const id = "food-carbs-range-input"
const min = 0
const max = 80
const step = 1
const polyglotPrefix = "components.entry_modal.food.carbs."

export function CarbsRangeInput(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const carbs = Number(e.target.value)

      updateActivity({
        ...activity,
        carbs,
      })
    },
    [
      updateActivity,
      activity,
    ],
  )

  return (
    <div className={styles.row}>
      <SliderValue sizePreset="large">
        <SliderValueInput
          min={min}
          max={max}
          step={0.1}
          value={typeof activity.carbs === "number" ? round(
            activity.carbs,
            1,
          ) : ""}
          onChange={onChange}
        />
        <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}label_unit`)}>
          <SliderValueLabel htmlFor={id}>
            {polyglot.t(`${polyglotPrefix}label_detail`)}
          </SliderValueLabel>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          min={min}
          max={max}
          step={step}
          id={id}
          value={activity.carbs ?? min}
          onChange={onChange}
        />
        <SliderTracks
          min={min}
          max={max}
        />
      </Slider>
    </div>
  )
}
