import {
  GearOutlineIcon,
  IconButton,
} from "@tandem-mobile/react-components"
import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"
import styles from "./InsulinAndMedication.module.scss"
import { useAccountMedicationModalContext } from "src/components"
import type { AccountMedication } from "src/types"

interface Props {
  accountMedication: DeepReadonlyObject<AccountMedication>;
}

export function AccountMedicationComponent(props: Props) {
  const { accountMedication } = props
  const { openAccountMedicationModal } = useAccountMedicationModalContext()

  const onClick = useCallback(
    () => {
      openAccountMedicationModal(accountMedication)
    },
    [
      accountMedication,
      openAccountMedicationModal,
    ],
  )

  // TODO componentize like CGMMenuItem ?
  return (
    <li className={styles.listItem}>
      <div className={styles.listItemText}>
        <div className={styles.listItemTopRow}>
          <span className={styles.listItemName}>
            {accountMedication.med.name}
          </span>
          <span className={styles.listItemUnit}>
            {accountMedication.units}
          </span>
        </div>
        <div className={styles.listItemDescription}>
          {accountMedication.med.description}
        </div>
      </div>
      <IconButton
        className={styles.listItemSettingsButton}
        stylePreset="withoutBackground"
        onClick={onClick}
      >
        <GearOutlineIcon />
      </IconButton>
    </li>
  )
}
