import {
  InfoButton,
  Toggle,
  Label,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import styles from "./AccountInsulinModal.module.scss"
import type { EditableAccountInsulin } from "./EditableAccountInsulin"
import { usePolyglot } from "src/contexts"

interface Props {
  currentAccountInsulin: EditableAccountInsulin;
  editAccountInsulin: (e: EditableAccountInsulin) => void;
}

const id = "insulin_is_basal_toggle"
const polyglotPrefix = "components.account_insulin_modal.basal."

/*
 * renders a toggle element for choosing if insulin is basal
 */
export function InsulinIsBasalToggle(props: Props) {
  const {
    currentAccountInsulin,
    editAccountInsulin,
  } = props

  const polyglot = usePolyglot()

  const onToggleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      editAccountInsulin({
        ...currentAccountInsulin,
        is_basal: e.target.checked,
      })
    },
    [
      currentAccountInsulin,
      editAccountInsulin,
    ],
  )

  // the toggle is disabled once the account insulin is saved because the
  // server doesn't allow changes to "is_basal" after creation
  return (
    <div className={styles.toggleRow}>
      <Label htmlFor={id}
        sizePreset="small"
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Toggle
        id={id}
        checked={currentAccountInsulin.is_basal}
        onChange={onToggleChange}
        disabled={!!currentAccountInsulin.id}
      />
    </div>
  )
}
