import {
  Legend,
  InfoButton,
  FieldSet,
  SelectButtons,
  SelectButton,
} from "@tandem-mobile/react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"
import { GraphDotColor } from "src/types"

const polyglotPrefix = "pages.settings.display.dot_color."

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

// note this matches the defaults in Graph/GlucoseReading.tsx
export function DotColor(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentDotColor = useDeviceSettingsProperty("dot_color")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.value as GraphDotColor,
    [],
  )

  const onValueChange = useCallback(
    (dotColor: UserDeviceSettings["dot_color"]) => {
      updateSettings({
        dot_color: dotColor,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentDotColor,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <FieldSet>
      <Legend>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}label`)}>
          <p>
            {polyglot.t(`${polyglotPrefix}description_summary`)}
          </p>
          <p>
            {polyglot.t(`${polyglotPrefix}description_theme`)}
          </p>
          <p>
            {polyglot.t(`${polyglotPrefix}description_readings`)}
          </p>
        </InfoButton>
      </Legend>
      <SelectButtons className={styles.selectContainer}>
        <SelectButton
          htmlFor="graph-dot-color-white"
          name="graph-dot-color"
          value={GraphDotColor.White}
          checked={value === GraphDotColor.White || value === GraphDotColor.Default}
          onChange={onChange}
        >
          {polyglot.t(`${polyglotPrefix}options.white`)}
        </SelectButton>
        <SelectButton
          htmlFor="graph-dot-color-reading-based"
          name="graph-dot-color"
          value={GraphDotColor.ReadingBased}
          checked={value === GraphDotColor.ReadingBased}
          onChange={onChange}
        >
          {polyglot.t(`${polyglotPrefix}options.reading_based`)}
        </SelectButton>
      </SelectButtons>
    </FieldSet>
  )
}
