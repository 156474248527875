import {
  Label,
  SortableList,
} from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import styles from "./Activity.module.scss"
import { OverlayActivityButton } from "./OverlayActivityButton"
import { SortableActivityButton } from "./SortableActivityButton"
import { usePolyglot } from "src/contexts"
import { useDeviceSettingsProperty } from "src/hooks"
import type {
  FeedButton,
  Settings,
} from "src/types"
import { enforceFeedButtons } from "src/utils"

const polyglotPrefix = "pages.settings.activity.activity_button."

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function ActivityButtons(props: Props) {
  const {
    updateSettings,
    updatedAt,
  } = props

  const polyglot = usePolyglot()
  const editedAtRef = useRef<string>(dayjs().toISOString())
  const currentFeedButtons = useDeviceSettingsProperty("feed_buttons")

  const [
    sortableActivities,
    setSortableActivities,
  ] = useState<Array<FeedButton>>(enforceFeedButtons(currentFeedButtons))

  useEffect(
    () => {
      if (!updatedAt || dayjs(updatedAt).isAfter(editedAtRef.current)) {
        setSortableActivities(enforceFeedButtons(currentFeedButtons))
      }
    },
    [
      editedAtRef,
      updatedAt,
      currentFeedButtons,
      setSortableActivities,
    ],
  )

  const onChange = useCallback(
    (sortedActivities: Array<FeedButton>) => {
      setSortableActivities(sortedActivities)

      updateSettings({
        feed_buttons: sortedActivities,
      })

      editedAtRef.current = dayjs().toISOString()
    },
    [
      setSortableActivities,
      updateSettings,
    ],
  )

  return (
    <div className={styles.activityButtons}>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
      </Label>
      <div className={styles.activityButtonsDescription}>
        {polyglot.t(`${polyglotPrefix}description`)}
      </div>
      <SortableList
        items={sortableActivities}
        onChange={onChange}
        SortableItem={SortableActivityButton}
        OverlayItem={OverlayActivityButton}
      />
    </div>
  )
}
