import {
  SortableGrid,
  useToasterContext,
} from "@tandem-mobile/react-components"
import {
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  SortableTile,
  OverlayTile,
} from "./Tile"
import { useUpdateInsights } from "src/hooks"
import { ReorderInsightsErrorToast } from "src/toasts"
import type {
  Insight,
  InsightKey,
  InsightRange,
} from "src/types"

const idSeparator = "|-|"

interface SortableInsight {
  insightKey: InsightKey;
  id: string;
  range?: InsightRange;
  units?: number;
}

function insightToSortable(insight: Insight): SortableInsight {
  return {
    insightKey: insight.key,
    id: `${insight.key}${idSeparator}${insight.range}`,
    range: insight.range,
    units: insight.units,
  }
}

function sortableToInsight(sortable: SortableInsight): Insight {
  return {
    key: sortable.insightKey,
    range: sortable.range,
    units: sortable.units,
  }
}

interface Props {
  insights: Array<Insight>;
}

export function SortableTiles(props: Props) {
  const { insights } = props
  const updateInsights = useUpdateInsights()
  const { showToast } = useToasterContext()

  const [
    sortableInsights,
    setSortableInsights,
  ] = useState<Array<SortableInsight>>(
    insights.map(
      (insight) => insightToSortable(insight),
    ),
  )

  useEffect(
    () => {
      const newSortableInsights = insights.map(
        (insight) => insightToSortable(insight),
      )

      setSortableInsights(newSortableInsights)
    },
    [
      insights,
      setSortableInsights,
    ],
  )

  const updateInsightOrder = useCallback(
    (sortedInsights: Array<SortableInsight>) => {
      void (async () => {
        setSortableInsights(sortedInsights)

        const newInsights = sortedInsights.map(sortableToInsight)

        try {
          await updateInsights(newInsights)
        } catch (e) {
          showToast(
            "reorder-insights-error-toast",
            ReorderInsightsErrorToast,
          )
        }
      })()
    },
    [
      setSortableInsights,
      updateInsights,
      showToast,
    ],
  )

  return (
    <SortableGrid
      items={sortableInsights}
      onChange={updateInsightOrder}
      SortableItem={SortableTile}
      OverlayItem={OverlayTile}
    />
  )
}
