import {
  IconButton,
  DashFillIcon,
} from "@tandem-mobile/react-components"
import classNames from "classnames"
import {
  useCallback,
  useState,
  useLayoutEffect,
} from "react"
import styles from "./ImageThumbnail.module.scss"
import { usePolyglot } from "src/contexts"
import type {
  EditableSubEntry,
  SubEntryType,
} from "src/types"

interface Props<T extends SubEntryType> {
  currentSubEntry: EditableSubEntry<T>;
  updateSubEntry: (s: Partial<EditableSubEntry<T>>) => void;
}

export function ImageThumbnail<T extends SubEntryType>(props: Props<T>) {
  const {
    currentSubEntry,
    updateSubEntry,
  } = props

  const polyglot = usePolyglot()

  const removeImage = useCallback(
    () => {
      updateSubEntry({
        image_id: null,
        image_url: undefined,
        thumbnail: undefined,
      })
    },
    [updateSubEntry],
  )

  const url = currentSubEntry.thumbnail || currentSubEntry.image_url

  const [
    imageLoaded,
    setImageLoaded,
  ] = useState<boolean>(false)

  useLayoutEffect(
    () => {
      setImageLoaded(false)
    },
    [
      url,
      setImageLoaded,
    ],
  )

  const onImageLoad = useCallback(
    () => {
      setImageLoaded(true)
    },
    [setImageLoaded],
  )

  if (!url) {
    return null
  }

  const className = classNames(
    styles.container,
    imageLoaded && styles.loaded,
  )

  return (
    <div className={className}>
      <IconButton
        className={styles.remove}
        onClick={removeImage}
      >
        <DashFillIcon />
      </IconButton>
      <img
        alt={polyglot.t("components.entry_modal.image_thumbnail.alt")}
        className={styles.thumbnail}
        src={url}
        onLoad={onImageLoad}
      />
    </div>
  )
}
