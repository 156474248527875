import {
  Label,
  Input,
} from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import styles from "./DateTimeInput.module.scss"
import { usePolyglot } from "src/contexts"
import type { EditableUserEntry } from "src/types"

const dateInputFormat = "YYYY-MM-DD"

interface Props {
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry?: (userEntry: EditableUserEntry) => void;
}

export function DateInput(props: Props) {
  const {
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const polyglot = usePolyglot()

  const dateValue = dayjs(currentUserEntry.started_at).format(dateInputFormat)

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const [
        oldYear,
        oldMonth,
        oldDay,
      ] = dateValue.split("-")
      const dateString = e.target.value
      const [
        year,
        month,
        day,
      ] = dateString.split("-")
      let date = dayjs(currentUserEntry.started_at)
      date = date.year(Number(year ?? oldYear))
      date = date.month(Number(month ?? oldMonth) - 1) // months are zero indexed
      date = date.date(Number(day ?? oldDay))

      setCurrentUserEntry?.({
        ...currentUserEntry,
        started_at: date.toISOString(),
      })
    },
    [
      currentUserEntry,
      setCurrentUserEntry,
      dateValue,
    ],
  )

  return (
    <div>
      <Label
        htmlFor="date"
        className={styles.label}
        sizePreset="small"
      >
        {polyglot.t("components.entry_modal.datetime.date_input_label")}
      </Label>
      <Input
        type="date"
        id="date"
        value={dateValue}
        onChange={onChange}
        className={styles.input}
      />
    </div>
  )
}
