import {
  ActionLink,
  ActionSubmit,
  Form,
  LargeInput,
  useToasterContext,
  ValidationContainer,
  ValidationMessage,
} from "@tandem-mobile/react-components"
import { Link } from "@tanstack/react-router"
import type {
  ChangeEventHandler,
  FormEventHandler,
} from "react"
import {
  useState,
  useCallback,
} from "react"
import { default as ReCAPTCHA } from "react-google-recaptcha"
import { PublicPageHeader } from "src/components"
import { usePolyglot } from "src/contexts"
import styles from "src/pages/ForgotPassword/ForgotPassword.module.scss"
import { useForgotPassword } from "src/pages/ForgotPassword/useForgotPassword"
import { useForgotPasswordRecaptcha } from "src/pages/ForgotPassword/useForgotPasswordRecaptcha"
import {
  ForgotPasswordEmailErrorToast,
  ForgotPasswordRecaptchaErrorToast,
} from "src/toasts"
import { Status } from "src/types"

const emailId = "user-email-input"
const polyglotPrefix = "pages.forgot_password."

export function OauthAuthorizeForgotPassword() {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const forgotPassword = useForgotPassword()
  const forgotPasswordRecaptcha = useForgotPasswordRecaptcha()

  const [
    sentEmail,
    setSentEmail,
  ] = useState<boolean>(false)

  const [
    formState,
    setFormState,
  ] = useState<{
    recaptchaToken: string;
    email: string;
  }>({
    recaptchaToken: "",
    email: "",
  })

  const onRecaptchaChange = useCallback(
    (token: string | null) => {
      setFormState({
        ...formState,
        recaptchaToken: token ?? "",
      })
    },
    [
      formState,
      setFormState,
      forgotPasswordRecaptcha,
    ],
  )

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        email: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onSubmit: FormEventHandler = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          const recaptchaResponse = await forgotPasswordRecaptcha(formState.recaptchaToken)

          if (!recaptchaResponse.data.success) {
            throw new Error("recaptcha request failed")
          }
        } catch (e) {
          showToast(
            "forgot-password-recaptcha-error",
            ForgotPasswordRecaptchaErrorToast,
          )
        }

        try {
          const forgotPasswordResponse = await forgotPassword(formState.email)

          if (forgotPasswordResponse.data.status === Status.Fail) {
            throw new Error("forgot password request failed")
          }

          setSentEmail(true)
        } catch (e) {
          showToast(
            "forgot-password-email-error",
            ForgotPasswordEmailErrorToast,
          )
        }
      })()
    },
    [
      formState,
      forgotPassword,
      forgotPasswordRecaptcha,
      showToast,
      setSentEmail,
    ],
  )

  return (
    <div className={styles.container}>
      <PublicPageHeader backRoutePath="/oauth/authorize">
        {polyglot.t(`${polyglotPrefix}title`)}
      </PublicPageHeader>
      <Form
        className={styles.form}
        onSubmit={onSubmit}
      >
        {sentEmail ? (
          <>
            <p>
              {polyglot.t(
                `${polyglotPrefix}success_message`,
                { email: formState.email },
              )}
            </p>
            <ActionLink
              className={styles.actionLink}
              stylePreset="secondary"
              asChild
            >
              <Link
                to="/oauth/authorize"
              >
                {polyglot.t(`${polyglotPrefix}done_cta`)}
              </Link>
            </ActionLink>
          </>
        ) : (
          <>
            <ValidationContainer>
              <LargeInput
                id={emailId}
                placeholder={polyglot.t(`${polyglotPrefix}email.placeholder`)}
                className={styles.input}
                type="email"
                value={formState.email}
                onChange={onEmailChange}
                required
              />
              <ValidationMessage
                invalidMessage={{
                  valueMissing: polyglot.t("validation_message.value_missing"),
                  typeMismatch: polyglot.t("validation_message.email_type_mismatch"),
                }}
              />
            </ValidationContainer>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
              onChange={onRecaptchaChange}
              className={styles.recaptcha}
            />
            <ActionSubmit
              value={polyglot.t(`${polyglotPrefix}submit_cta`)}
              stylePreset="primary"
              className={styles.submit}
              disabled={!formState.recaptchaToken}
            />
          </>
        )}
      </Form>
    </div>
  )
}
