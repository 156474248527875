import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandem-mobile/react-components"
import { useCallback } from "react"
import type { ChangeEventHandler } from "react"

import { defaultMedicationActivity } from "./defaultMedicationActivity"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type { MedicationActivity } from "src/types"

interface Props {
  units?: string;
  activity: MedicationActivity;
  updateActivity: (a: MedicationActivity) => void;
}

const id = "medication-units-range-input"
const max = 10
const min = 0
const step = 1

/*
 * renders a number label and range input for choosing number of units
 */
export function MedicationFormUnitsRangeInput(props: Props) {
  const {
    units,
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  // save changes to num_units
  const onNumUnitsChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const numUnits = Number(e.target.value)

      updateActivity({
        ...activity,
        num_units: numUnits,
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  const unset = activity.num_units === 0 &&
    activity.medication.account_med_id === defaultMedicationActivity.medication.account_med_id

  return (
    <div className={styles.row}>
      <SliderValue sizePreset="large">
        <SliderValueInput
          max={max}
          min={min}
          onChange={onNumUnitsChange}
          value={unset ? "" : activity.num_units}
        />
        <SliderValueLabelContainer>
          <SliderValueLabel htmlFor={id}>
            {units || polyglot.t(
              "components.entry_modal.medication.default_label_detail",
              activity.num_units,
            )}
          </SliderValueLabel>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          min={min}
          max={max}
          step={step}
          id={id}
          value={activity.num_units}
          onChange={onNumUnitsChange}
        />
        <SliderTracks
          min={min}
          max={max}
        />
      </Slider>
    </div>
  )
}
