import { ActionButton } from "@tandem-mobile/react-components"
import classNames from "classnames"
import type { MouseEventHandler } from "react"
import { useCallback } from "react"
import styles from "./FoodSearchButton.module.scss"
import { usePolyglot } from "src/contexts"

interface Props {
  textValue: string;
  hidden: boolean;
  setSearchValue: (s: string) => void;
}

/*
 * this component renders a button that submits the search in the text input
 */
export function FoodSearchButton(props: Props) {
  const {
    hidden,
    textValue,
    setSearchValue,
  } = props

  const polyglot = usePolyglot()

  const className = classNames(
    styles.searchButton,
    {
      [styles.hiddenSearchButton]: hidden,
    },
  )

  const onSearchClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      setSearchValue(textValue)
    },
    [
      textValue,
      setSearchValue,
    ],
  )

  return (
    <ActionButton
      className={className}
      onClick={onSearchClick}
      stylePreset="primary"
      disabled={!textValue}
    >
      {polyglot.t("components.food_search.search_cta")}
    </ActionButton>
  )
}
