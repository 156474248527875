import {
  ActionButton,
  Dialog,
  DialogHeading,
  DialogContent,
  DialogButtons,
  DialogButton,
  DialogTrigger,
} from "@tandem-mobile/react-components"
import type { MouseEventHandler } from "react"
import { useCallback } from "react"

import {
  usePolyglot,
  useAuthContext,
} from "src/contexts"

const polyglotPrefix = "pages.settings.account.log_out."

export function LogOut() {
  const polyglot = usePolyglot()
  const { signOut } = useAuthContext()

  const onSignOutClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      void signOut()
    },
    [signOut],
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <ActionButton stylePreset="secondary">
          {polyglot.t(`${polyglotPrefix}trigger_cta`)}
        </ActionButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}title`)}
        </DialogHeading>
        <DialogButtons>
          <DialogButton
            stylePreset="destructive"
            onClick={onSignOutClick}
          >
            {polyglot.t(`${polyglotPrefix}confirm_cta`)}
          </DialogButton>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
