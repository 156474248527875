import {
  ActionButton,
  Dialog,
  DialogCloseX,
  DialogContent,
  DialogHeading,
  DialogBody,
  DialogButton,
  DialogButtons,
  useToasterContext,
} from "@tandem-mobile/react-components"
import {
  useNavigate,
  useRouterState,
} from "@tanstack/react-router"
import dayjs from "dayjs"
import {
  useState,
  useCallback,
  useMemo,
  // useEffect,
} from "react"
import type { ReactNode } from "react"
import styles from "./DataSource.module.scss"
import { useDataSourceAlreadyExistsDialogContext } from "./DataSourceAlreadyExistsDialogContext"
import { useNoNewReadingsDialogContext } from "./NoNewReadingsDialogContext"
import { usePolyglot } from "src/contexts"
import { useCreateDexcomSource } from "src/hooks"
import {
  AddSourceErrorToast,
  AddSourceSuccessToast,
} from "src/toasts"
import { SourceType } from "src/types"
import type { RoutePath } from "src/types"

const polyglotPrefix = "pages.settings.data_source.connect_to_dexcom_dialog."

interface Props {
  children: ReactNode;
}

export function DexcomConnectDialog(props: Props) {
  const navigate = useNavigate()
  const currentPath = useRouterState({
    // note use .href if you want to include query params
    select: (s) => s.resolvedLocation.pathname,
  }) as RoutePath

  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const createDexcomSource = useCreateDexcomSource()
  const { openNoNewReadingsDialog } = useNoNewReadingsDialogContext()
  const { openDataSourceAlreadyExistsDialog } = useDataSourceAlreadyExistsDialogContext()

  const code = useMemo(
    () => (new URLSearchParams(window.location.search)).get("code"),
    [],
  )

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(
    !!code,
  )

  const clearDexcomCode = useCallback(
    () => {
      // clear the code from the url params
      void navigate({
        to: currentPath,
        replace: true,
        params: {},
      })
    },
    [
      navigate,
      currentPath,
    ],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        clearDexcomCode()
      }

      setIsOpen(newOpen)
    },
    [
      setIsOpen,
      clearDexcomCode,
    ],
  )

  const onClick = useCallback(
    () => {
      if (code && createDexcomSource) {
        void (async () => {
          try {
            const response = await createDexcomSource(code)
            const { source } = response.data

            showToast(
              "add-dexcom-source-success-toast",
              AddSourceSuccessToast,
            )

            if (source.already_exists === true) {
              openDataSourceAlreadyExistsDialog()
            }

            const noNewReadings = source.last_point_at === null || (
              dayjs()
                .subtract(
                  10,
                  "minutes",
                )
                .isAfter(source.last_point_at)
            )

            if (noNewReadings) {
              openNoNewReadingsDialog(SourceType.DexcomRest)
            }

            onOpenChange(false)
          } catch (e) {
            showToast(
              "add-dexcom-source-error-toast",
              AddSourceErrorToast,
            )
          }
        })()
      }
    },
    [
      code,
      createDexcomSource,
      currentPath,
      navigate,
      openDataSourceAlreadyExistsDialog,
      openNoNewReadingsDialog,
      onOpenChange,
      showToast,
    ],
  )

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      {props.children}
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}heading`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogBody className={styles.dialogBody}>
          <div>
            {polyglot.t(`${polyglotPrefix}message`)}
          </div>
          <DialogButtons>
            <DialogButton
              stylePreset="secondary"
            >
              {polyglot.t(`${polyglotPrefix}cancel_cta`)}
            </DialogButton>
            <ActionButton
              onClick={onClick}
              stylePreset="primary"
              className={styles.dialogMultiButton}
            >
              {polyglot.t(`${polyglotPrefix}confirm_cta`)}
            </ActionButton>
          </DialogButtons>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
