import { Toast } from "@tandem-mobile/react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
  title: string;
}

export function FavoriteEntrySuccessToast(props: Props) {
  const {
    id,
    title,
  } = props

  const polyglot = usePolyglot()

  const body = polyglot.t(
    "toasts.favorite_entry_success.body",
    { title },
  )

  return (
    <Toast
      stylePreset="success"
      body={body}
      id={id}
    />
  )
}
