import {
  DialogHeading,
  DialogBody,
} from "@tandem-mobile/react-components"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"

const polyglotPrefix = "components.entry_modal.food.carbs_warning."
export function FoodCarbsWarning() {
  const polyglot = usePolyglot()

  return (
    <>
      <DialogHeading>
        {polyglot.t(`${polyglotPrefix}dialog_heading`)}
      </DialogHeading>
      <DialogBody className={styles.dialogBodyMultiParagraph}>
        <p className={styles.warningParagraph}>
          {polyglot.t(`${polyglotPrefix}dialog_body1`)}
        </p>
        <p className={styles.warningParagraph}>
          {polyglot.t(`${polyglotPrefix}dialog_body2`)}
        </p>
      </DialogBody>
    </>
  )
}
