import {
  FieldSet,
  SelectButton,
  SelectButtons,
  Legend,
  InfoButton,
} from "@tandem-mobile/react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  UserDeviceSettings,
  Settings,
} from "src/types"
import { ThemeAppearance } from "src/types"

const polyglotPrefix = "pages.settings.display.theme_appearance."
const inputName = "theme-appearance"

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function Appearance(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentThemeAppearance = useDeviceSettingsProperty("theme_appearance")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.value as ThemeAppearance,
    [],
  )

  const onValueChange = useCallback(
    (themeAppearance: UserDeviceSettings["theme_appearance"]) => {
      updateSettings({
        theme_appearance: themeAppearance,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentThemeAppearance,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <FieldSet>
      <Legend>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Legend>
      <SelectButtons className={styles.selectContainer}>
        <SelectButton
          htmlFor="theme-appearance-os"
          name={inputName}
          value={ThemeAppearance.OS}
          onChange={onChange}
          checked={value === ThemeAppearance.OS}
        >
          {polyglot.t(`${polyglotPrefix}os`)}
        </SelectButton>
        <SelectButton
          htmlFor="theme-appearance-light"
          name={inputName}
          value={ThemeAppearance.Light}
          onChange={onChange}
          checked={value === ThemeAppearance.Light}
        >
          {polyglot.t(`${polyglotPrefix}light`)}
        </SelectButton>
        <SelectButton
          htmlFor="theme-appearance-dark"
          name={inputName}
          value={ThemeAppearance.Dark}
          onChange={onChange}
          checked={value === ThemeAppearance.Dark}
        >
          {polyglot.t(`${polyglotPrefix}dark`)}
        </SelectButton>
      </SelectButtons>
    </FieldSet>
  )
}
