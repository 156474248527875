import {
  Checkbox,
  CheckboxCheckMark,
  DialogContentForm,
  DialogHeading,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  ActionSubmit,
  DialogBody,
  LinkOutlineIcon,
  useDialogContext,
} from "@tandem-mobile/react-components"
import type {
  FormEventHandler,
  ChangeEventHandler,
} from "react"
import {
  useEffect,
  useCallback,
  useState,
} from "react"
import styles from "./DataSource.module.scss"

import { usePolyglot } from "src/contexts"

interface Props {
  onConfirm: () => void;
}

const polyglotPrefix = "pages.settings.data_source.consent_dialog."
const termsOfUseId = "terms-of-use-confirmation"
const privacyPolicyId = "privacy-policy-confirmation"

const defaultFormState = {
  termsOfUse: false,
  privacyPolicy: false,
}

export function DataConsentForm(props: Props) {
  const { onConfirm } = props

  const { open: isOpen } = useDialogContext()
  const polyglot = usePolyglot()

  const [
    formState,
    setFormState,
  ] = useState<{
    termsOfUse: boolean;
    privacyPolicy: boolean;
  }>(defaultFormState)

  useEffect(
    () => {
      if (isOpen) {
        setFormState(defaultFormState)
      }
    },
    [isOpen],
  )

  const onTermsOfUseChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        termsOfUse: e.target.checked,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onPrivacyPolicyChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        privacyPolicy: e.target.checked,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      onConfirm()
    },
    [onConfirm],
  )

  return (
    <DialogContentForm onSubmit={onSubmit}>
      <DialogHeading>
        {polyglot.t(`${polyglotPrefix}heading`)}
        <DialogCloseX />
      </DialogHeading>
      <DialogBody className={styles.dialogBody}>
        <div>
          {polyglot.t(`${polyglotPrefix}description`)}
        </div>
        <div
          className={styles.privacyCheckboxContainer}
          data-checked={formState.termsOfUse}
        >
          <Checkbox
            className={styles.checkbox}
            htmlFor={termsOfUseId}
            checked={formState.termsOfUse}
            onChange={onTermsOfUseChange}
            required
          >
            <CheckboxCheckMark />
            {polyglot.t(`${polyglotPrefix}terms_of_use.label`)}
          </Checkbox>
          <a
            href="https://sugarmate.io/tos"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <LinkOutlineIcon className={styles.linkIcon} />
          </a>
        </div>
        <div
          className={styles.privacyCheckboxContainer}
          data-checked={formState.privacyPolicy}
        >
          <Checkbox
            className={styles.checkbox}
            htmlFor={privacyPolicyId}
            checked={formState.privacyPolicy}
            onChange={onPrivacyPolicyChange}
            required
          >
            <CheckboxCheckMark />
            {polyglot.t(`${polyglotPrefix}privacy_policy.label`)}
          </Checkbox>
          <a
            href="https://sugarmate.io/privacy"
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            <LinkOutlineIcon className={styles.linkIcon} />
          </a>
        </div>
        <DialogButtons>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
          <ActionSubmit
            className={styles.dialogMultiButton}
            value={polyglot.t(`${polyglotPrefix}confirm_cta`)}
          />
        </DialogButtons>
      </DialogBody>
    </DialogContentForm>
  )
}
