import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
  InfoButton,
  Label,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
} from "react"

import type { DeepReadonlyObject } from "rxdb"

import styles from "./AccountInsulinModal.module.scss"
import type { EditableAccountInsulin } from "./EditableAccountInsulin"
import { emptyAccountInsulin } from "./EditableAccountInsulin"
import { usePolyglot } from "src/contexts"
import type { Insulin } from "src/types"
import {
  insulinPrecisionToUnits,
  insulinUnitsToPrecision,
} from "src/utils"

interface Props {
  currentInsulin?: DeepReadonlyObject<Insulin>;
  currentAccountInsulin: EditableAccountInsulin;
  editAccountInsulin: (e: EditableAccountInsulin) => void;
}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const precisionValues: number[] = [
  0.01,
  0.025,
  0.05,
  0.1,
  0.5,
  1,
  2,
  4,
  5,
  8,
  12,
].map(insulinUnitsToPrecision)

const id = "insulin_precision_select"
const polyglotPrefix = "components.account_insulin_modal.precision."

export function InsulinPrecisionSelect(props: Props) {
  const {
    currentInsulin,
    currentAccountInsulin,
    editAccountInsulin,
  } = props

  const polyglot = usePolyglot()

  const options: Array<Option> = useMemo(
    () => {
      const optionsArray: Array<Option> = [
        // the placeholder option
        {
          value: `${emptyAccountInsulin.precision ?? ""}`,
          children: polyglot.t(`${polyglotPrefix}placeholder`),
          disabled: true,
        },
      ]

      precisionValues.forEach((precisionValue) => {
        optionsArray.push({
          value: `${precisionValue}`,
          children: polyglot.t(
            "unit.num_units_abbreviated",
            { units: insulinPrecisionToUnits(precisionValue).toLocaleString(navigator.language) },
          ),
        })
      })

      return optionsArray
    },
    [polyglot],
  )

  // update the currentAccountInsulin's precision
  const onPrecisionChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const precision = Number(e.target.value)

      // update insulin precision
      editAccountInsulin({
        ...currentAccountInsulin,
        precision,
      })
    },
    [
      currentAccountInsulin,
      editAccountInsulin,
    ],
  )

  return (
    <div>
      <Label
        htmlFor={id}
        sizePreset="small"
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Dropdown>
        <DropdownSelect
          id={id}
          disabled={!currentInsulin}
          value={`${currentAccountInsulin.precision ?? ""}`}
          onChange={onPrecisionChange}
          required
        >
          {options.map(
            (selectOption) => (
              <DropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </DropdownSelect>
        <DropdownCurrentSelection />
        <DropdownCaret />
      </Dropdown>
    </div>
  )
}
