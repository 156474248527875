import {
  InfoButton,
  Label,
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
  useSortedSliderValueInputProps,
} from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react"

import styles from "./Ranges.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useGetGlucoseValue,
  useSetGlucoseValue,
  useGlucoseInputProps,
  useReadableGlucoseUnit,
  useDeviceSettingsProperty,
  useFormatNumberInputGlucoseValue,
} from "src/hooks"
import type { Settings } from "src/types"
import { roundForStep } from "src/utils"

const polyglotPrefix = "pages.settings.ranges.normal_range."

const inputProps = {
  min: 50,
  max: 300,
  step: 5,
}

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

const bottomNumberId = "range-normal-bottom-number"
const topNumberId = "range-normal-top-number"

export function NormalRange(props: Props) {
  const {
    updateSettings,
    updatedAt,
  } = props

  const polyglot = usePolyglot()
  const currentRangeBottom = useDeviceSettingsProperty("range_normal_bottom")
  const currentRangeTop = useDeviceSettingsProperty("range_normal_top")
  const editedAtRef = useRef<string>(dayjs().toISOString())

  const readableGlucoseUnit = useReadableGlucoseUnit()
  const formatNumberInputGlucoseValue = useFormatNumberInputGlucoseValue()
  const getGlucoseValue = useGetGlucoseValue()
  const setGlucoseValue = useSetGlucoseValue()

  const [
    valueOne,
    setValueOne,
  ] = useState<number>(currentRangeBottom)

  const [
    valueTwo,
    setValueTwo,
  ] = useState<number>(currentRangeTop)

  // when the settings property changes, update the state
  useEffect(
    () => {
      if (!updatedAt || dayjs(updatedAt).isAfter(editedAtRef.current)) {
        setValueOne(currentRangeBottom)
        setValueTwo(currentRangeTop)
      }
    },
    [
      editedAtRef,
      currentRangeBottom,
      currentRangeTop,
      updatedAt,
      setValueOne,
      setValueTwo,
    ],
  )

  // when value one changes, update the state and trigger a settings update
  const onValueOneChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = setGlucoseValue(Number(e.target.value))
      setValueOne(newValue)

      const chartBottom = Math.min(
        newValue,
        valueTwo,
      )
      const chartTop = Math.max(
        newValue,
        valueTwo,
      )

      updateSettings({
        range_normal_bottom: chartBottom,
        range_normal_top: chartTop,
      })

      editedAtRef.current = dayjs().toISOString()
    },
    [
      editedAtRef,
      setValueOne,
      valueTwo,
      updateSettings,
      setGlucoseValue,
    ],
  )

  // when value two changes, update the state and trigger a settings update
  const onValueTwoChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = setGlucoseValue(Number(e.target.value))
      setValueTwo(newValue)

      const chartBottom = Math.min(
        newValue,
        valueOne,
      )
      const chartTop = Math.max(
        newValue,
        valueOne,
      )

      updateSettings({
        range_normal_bottom: chartBottom,
        range_normal_top: chartTop,
      })

      editedAtRef.current = dayjs().toISOString()
    },
    [
      editedAtRef,
      setValueTwo,
      valueOne,
      updateSettings,
    ],
  )

  // shared props for all inputs
  const sharedProps = useGlucoseInputProps(inputProps)

  // the unique props for the first slider input
  const inputOneProps = useMemo(
    () => ({
      value: roundForStep(
        getGlucoseValue(valueOne),
        sharedProps.step,
      ),
      onChange: onValueOneChange,
    }),
    [
      valueOne,
      getGlucoseValue,
      onValueOneChange,
      sharedProps.step,
    ],
  )

  // the unique props for the second slider input
  const inputTwoProps = useMemo(
    () => ({
      value: roundForStep(
        getGlucoseValue(valueTwo),
        sharedProps.step,
      ),
      onChange: onValueTwoChange,
    }),
    [
      valueTwo,
      getGlucoseValue,
      onValueTwoChange,
      sharedProps.step,
    ],
  )

  const [
    lowInputProps,
    highInputProps,
  ] = useSortedSliderValueInputProps(
    inputOneProps,
    inputTwoProps,
  )

  return (
    <div>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Label>
      <div className={styles.inputContainer}>
        <SliderValue
          sizePreset="small"
          alignmentPreset="center"
        >
          <SliderValueInput
            {...sharedProps}
            {...lowInputProps}
            id={bottomNumberId}
            value={formatNumberInputGlucoseValue(lowInputProps.value)}
          />
          <SliderValueLabelContainer>
            <SliderValueLabel htmlFor={bottomNumberId}>
              {readableGlucoseUnit}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
        <Slider className={styles.rangeInputSlider}>
          <SliderInput
            {...inputOneProps}
            {...sharedProps}
          />
          <SliderInput
            {...inputTwoProps}
            {...sharedProps}
          />
          <SliderTracks
            min={sharedProps.min}
            max={sharedProps.max}
          />
        </Slider>
        <SliderValue
          sizePreset="small"
          alignmentPreset="center"
        >
          <SliderValueInput
            {...sharedProps}
            {...highInputProps}
            id="range-normal-top-number"
            value={formatNumberInputGlucoseValue(highInputProps.value)}
          />
          <SliderValueLabelContainer>
            <SliderValueLabel htmlFor={topNumberId}>
              {readableGlucoseUnit}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      </div>
    </div>
  )
}
