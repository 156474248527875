import {
  ActionSubmit,
  FieldSet,
  Legend,
  Modal,
  ModalBody,
  ModalButton,
  ModalButtons,
  ModalCloseX,
  ModalContentForm,
  ModalHeading,
  RadioButton,
  RadioButtonCheckMark,
  RadioButtons,
  useToasterContext,
} from "@tandem-mobile/react-components"
import type {
  ReactNode,
  FormEventHandler,
} from "react"
import {
  useCallback,
  useState,
  useMemo,
} from "react"

import { DayMetricContext } from "./DayMetricContext"
import styles from "./DayMetricSelector.module.scss"
import { DiscardChangesDialog } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useDeviceSettingsProperty,
  useUpdateSettings,
} from "src/hooks"
import { SaveDayMetricErrorToast } from "src/toasts"
import { DayStat } from "src/types"

const polyglotPrefix = "pages.home.activity_list.day_stat_selector."

const radioButtons: Array<DayStat> = [
  DayStat.NormalRange,
  DayStat.AverageGlucose,
  DayStat.WakeUpGlucose,
  DayStat.BedTimeGlucose,
  DayStat.TotalCarbs,
  DayStat.TotalUnits,
  DayStat.TotalCalories,
  DayStat.Exercise,
  DayStat.StandardDeviation,
]

interface Props {
  children: ReactNode;
}

export function DayMetricContextProvider(props: Props) {
  const updateSettings = useUpdateSettings()
  const { showToast } = useToasterContext()
  const polyglot = usePolyglot()

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState<boolean>(false)

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)

  const dayMetric = useDeviceSettingsProperty("day_stat_header")

  const [
    currentDayMetric,
    setCurrentDayMetric,
  ] = useState<DayStat | undefined>(dayMetric)

  const openDayMetricModal = useCallback(
    () => {
      setCurrentDayMetric(dayMetric)
      setIsModalOpen(true)
    },
    [
      dayMetric,
      setCurrentDayMetric,
      setIsModalOpen,
    ],
  )

  const closeDayMetricModal = useCallback(
    () => {
      setIsModalOpen(false)
    },
    [setIsModalOpen],
  )

  const contextValue = useMemo(
    () => ({
      openDayMetricModal,
    }),
    [openDayMetricModal],
  )

  const hasChanged = currentDayMetric !== dayMetric

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        setIsModalOpen(true)
      } else if (hasChanged) {
        setIsDiscardOpen(true)
      } else {
        setIsModalOpen(false)
      }
    },
    [
      hasChanged,
      setIsDiscardOpen,
      setIsModalOpen,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          await updateSettings({
            day_stat_header: currentDayMetric,
          })
          setIsModalOpen(false)
        } catch (e) {
          showToast(
            "save-day-stat-error-toast",
            SaveDayMetricErrorToast,
          )
        }
      })()
    },
    [
      showToast,
      currentDayMetric,
      setIsModalOpen,
      updateSettings,
    ],
  )

  return (
    <DayMetricContext.Provider value={contextValue}>
      <Modal
        open={isModalOpen}
        onOpenChange={onOpenChange}
      >
        {props.children}
        <ModalContentForm onSubmit={onSubmit}>
          <ModalHeading>
            {polyglot.t(`${polyglotPrefix}title`)}
            <ModalCloseX />
          </ModalHeading>
          <ModalBody>
            <FieldSet className={styles.fieldSet}>
              <Legend className={styles.legend}>
                {polyglot.t(`${polyglotPrefix}description`)}
              </Legend>
              <RadioButtons>
                {radioButtons.map(
                  (value) => (
                    <RadioButton
                      key={value}
                      name="day-metric"
                      value={value}
                      checked={currentDayMetric === value}
                      onClick={() => setCurrentDayMetric(value)}
                      htmlFor={value}
                      required
                    >
                      <RadioButtonCheckMark/>
                      {polyglot.t(`${polyglotPrefix}${value}`)}
                    </RadioButton>
                  ),
                )}
              </RadioButtons>
            </FieldSet>
            <ModalButtons>
              <ModalButton stylePreset="secondary">
                {polyglot.t(`${polyglotPrefix}cancel_cta`)}
              </ModalButton>
              <ActionSubmit
                className={styles.modalMultiButton}
                value={polyglot.t(`${polyglotPrefix}save_cta`)}
                disabled={!hasChanged}
              />
            </ModalButtons>
          </ModalBody>
        </ModalContentForm>
        <DiscardChangesDialog
          open={isDiscardOpen}
          setOpen={setIsDiscardOpen}
          discardChanges={closeDayMetricModal}
        />
      </Modal>
    </DayMetricContext.Provider>
  )
}
