import {
  ModalTrigger,
  PlusIcon,
} from "@tandem-mobile/react-components"
import classNames from "classnames"
import { forwardRef } from "react"
import type {
  ForwardedRef,
  HTMLAttributes,
} from "react"

import { useTabContext } from "../TabContext"
import { AddInsightModal } from "./AddInsightModal"
import { CalculationsContextProvider } from "./CalculationsContext"
import { EditInsightModal } from "./EditInsightModal"
import styles from "./Insights.module.scss"
import { useInsightsContext } from "./InsightsContext"
import { InsightsHeader } from "./InsightsHeader"
import { SortableTiles } from "./SortableTiles"
import { useAvailableInsightRanges } from "./useAvailableInsightRanges"
import { usePolyglot } from "src/contexts"
import { useDeviceSettingsProperty } from "src/hooks"
import { Tab } from "src/types"

type Props = HTMLAttributes<HTMLDivElement>

export const Insights = forwardRef<HTMLDivElement, Props>(
  function Insights(props: Props, propRef: ForwardedRef<HTMLDivElement>) {
    const { currentTab } = useTabContext()
    const polyglot = usePolyglot()
    const { isEditing } = useInsightsContext()

    // note this is returning new data every time ?
    const insights = useDeviceSettingsProperty("gauges")

    const {
      defaultInsightRangeByKey,
      availableInsightRangesByKey,
    } = useAvailableInsightRanges(insights)

    return (
      <CalculationsContextProvider insights={insights}>
        <div
          {...props}
          className={classNames(
            props.className,
            styles.container,
          )}
          ref={propRef}
        >
          <InsightsHeader />
          <div
            className={classNames(
              styles.tiles,
              {
                [styles.tilesHidden]: currentTab !== Tab.Insights,
              },
            )}
          >
            <EditInsightModal availableInsightRangesByKey={availableInsightRangesByKey}>
              <SortableTiles insights={insights} />
            </EditInsightModal>
            {isEditing && (
              <AddInsightModal defaultInsightRangeByKey={defaultInsightRangeByKey}>
                <ModalTrigger className={styles.newInsightTile}>
                  <PlusIcon className={styles.newInsightTileIcon} />
                  <div>
                    {polyglot.t("pages.home.insights.add_insight_tile_cta")}
                  </div>
                </ModalTrigger>
              </AddInsightModal>
            )}
          </div>
        </div>
      </CalculationsContextProvider>
    )
  },
)
