import {
  HelpOutlineIcon,
  EmailAttachmentIcon,
} from "@tandem-mobile/react-components"
import type {
  MouseEventHandler,
  FunctionComponent,
  SVGProps,
} from "react"
import { useCallback } from "react"

import styles from "./HelpAndFeedbackIndex.module.scss"
import {
  PageLink,
  PageLinkButton,
} from "src/components"

export function HelpAndFeedbackIndex() {
  const onContactClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      window.Intercom("show")
    },
    [],
  )

  return (
    <div className={styles.container}>
      <PageLink
        routePath={"/help_and_feedback/help" as const}
        polyglotPhraseKey={"pages.help_and_feedback.pages.help"}
        Icon={HelpOutlineIcon as FunctionComponent<SVGProps<SVGElement>>}
      />
      <PageLinkButton
        onClick={onContactClick}
        polyglotPhraseKey={"pages.help_and_feedback.pages.contact"}
        Icon={EmailAttachmentIcon as FunctionComponent<SVGProps<SVGElement>>}
      />
    </div>
  )
}
