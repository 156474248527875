import {
  MenuModalButton,
  MenuModalButtonIcon,
  MenuModalButtonText,
  ModalBody,
  ModalCloseX,
  ModalHeading,
} from "@tandem-mobile/react-components"
import type { MouseEventHandler } from "react"
import { useCallback } from "react"


import { DateTimeInput } from "./DateTimeInput"
import {
  ActivityIcon,
  ActivityName,
} from "src/components/Activity"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type {
  SubEntryType,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  createSubEntry: (subentry: EditableSubEntry<SubEntryType>) => void;
  currentSubEntry: EditableSubEntry<undefined>;
  currentUserEntry: EditableUserEntry;
}

const polyglotPrefix = "components.entry_modal."

// TODO should this also use the same order as the feed buttons ?
const availableTypes: Array<SubEntryType> = [
  ActivityType.Food,
  ActivityType.Exercise,
  ActivityType.Insulin,
  ActivityType.Medication,
  ActivityType.Glucose,
  ActivityType.DeviceChange,
  ActivityType.Liquid,
  ActivityType.Note,
]

export function SubEntryTypeSelector(props: Props) {
  const {
    createSubEntry,
    currentUserEntry,
    currentSubEntry,
  } = props

  const polyglot = usePolyglot()

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (e.target instanceof HTMLElement) {
        const subEntryType = e.target.dataset.type

        createSubEntry({
          ...currentSubEntry,
          subentry_type: subEntryType as SubEntryType,
        })
      }
    },
    [
      currentSubEntry,
      createSubEntry,
    ],
  )

  const title = currentUserEntry.subentries.length ?
    polyglot.t(`${polyglotPrefix}add_another_item_title`) :
    polyglot.t(`${polyglotPrefix}add_item_title`)

  return (
    <>
      <ModalHeading>
        <div>
          {title}
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            readonly
          />
        </div>
        <ModalCloseX />
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        {
          availableTypes.map(
            (subEntryType) => (
              <MenuModalButton
                data-type={subEntryType}
                key={subEntryType}
                onClick={onClick}
              >
                <MenuModalButtonIcon data-type={subEntryType}>
                  <ActivityIcon activityType={subEntryType} />
                </MenuModalButtonIcon>
                <MenuModalButtonText data-type={subEntryType}>
                  <ActivityName activityType={subEntryType} />
                </MenuModalButtonText>
              </MenuModalButton>
            ),
          )
        }
      </ModalBody>
    </>
  )
}
