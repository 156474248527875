import { SortableListItem } from "@tandem-mobile/react-components"
import styles from "./Activity.module.scss"
import { ActivityButton } from "./ActivityButton"
import type { FeedButton } from "src/types"

interface Props {
  id: string;
}

// TODO convert to menu button ?
export function SortableActivityButton(props: Props) {
  const { id } = props

  return (
    <SortableListItem
      id={id}
      className={styles.activityButton}
    >
      <ActivityButton activityType={id as FeedButton} />
    </SortableListItem>
  )
}
