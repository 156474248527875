import { useToasterContext } from "@tandem-mobile/react-components"
import {
  useCallback,
  useState,
} from "react"

import { useOauthAuthorize } from "./useOauthAuthorize"
import { useSignIn } from "./useSignIn"
import { PublicPageHeader } from "src/components"
import { usePolyglot } from "src/contexts"
import { NewUserAccountForm } from "src/pages/SignUp/NewUserAccountForm"
import { NewUserPrivacyForm } from "src/pages/SignUp/NewUserPrivacyForm"
import type { PrivacyFormData } from "src/pages/SignUp/PrivacyFormData"
import styles from "src/pages/SignUp/SignUp.module.scss"
import { SignInAfterSignUpErrorToast } from "src/toasts"

export function OauthAuthorizeSignUp() {
  const [
    completedPrivacyForm,
    setCompletedPrivacyForm,
  ] = useState<PrivacyFormData | undefined>(undefined)

  const polyglot = usePolyglot()
  const signIn = useSignIn()
  const oauthAuthorize = useOauthAuthorize()
  const { showToast } = useToasterContext()

  const onPrivacyFormSubmit = useCallback(
    (privacyFormData: PrivacyFormData) => {
      setCompletedPrivacyForm(privacyFormData)
    },
    [setCompletedPrivacyForm],
  )

  const onAccountCreate = useCallback(
    async (
      email: string,
      password: string,
    ) => {
      try {
        const signInResponse = await signIn(
          email,
          password,
        )

        await oauthAuthorize(signInResponse.data.access_token)
      } catch (e) {
        showToast(
          "sign-in-after-sign-up-error-toast",
          SignInAfterSignUpErrorToast,
        )
      }
    },
    [
      signIn,
      oauthAuthorize,
    ],
  )

  const goBack = useCallback(
    () => {
      setCompletedPrivacyForm(undefined)
    },
    [],
  )

  return (
    <div className={styles.container}>
      {completedPrivacyForm
        ? (
          <NewUserAccountForm
            privacyFormData={completedPrivacyForm}
            goBack={goBack}
            onAccountCreate={onAccountCreate}
          />
        )
        : (
          <>
            <PublicPageHeader backRoutePath="/oauth/authorize">
              {polyglot.t("pages.sign_up.new_user_privacy.title")}
            </PublicPageHeader>
            <NewUserPrivacyForm onSubmit={onPrivacyFormSubmit} />
          </>
        )
      }
    </div>
  )
}
