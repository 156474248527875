import {
  Label,
  InfoButton,
  Toggle,
} from "@tandem-mobile/react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"

const polyglotPrefix = "pages.settings.display.graph_icon_text."
const id = "graph-icon-text"

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function GraphIconText(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentGraphIconText = useDeviceSettingsProperty("graph_icon_info")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.checked,
    [],
  )

  const onValueChange = useCallback(
    (graphIconText: UserDeviceSettings["graph_icon_info"]) => {
      updateSettings({
        graph_icon_info: graphIconText,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentGraphIconText,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <div className={styles.toggleRow}>
      <Label htmlFor={id}>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Label>
      <Toggle
        id={id}
        checked={value}
        onChange={onChange}
      />
    </div>
  )
}
