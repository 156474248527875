import {
  Label,
  InfoButton,
  Toggle,
} from "@tandem-mobile/react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Activity.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"

const polyglotPrefix = "pages.settings.activity.hide_notes_history."
const id = "show-difference"

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function HideNotesHistory(props: Props) {
  const {
    updateSettings,
    updatedAt,
  } = props

  const polyglot = usePolyglot()
  const currentHideNotesHistory = useDeviceSettingsProperty("hide_notes_history")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.checked,
    [],
  )

  const onValueChange = useCallback(
    (hideNotesHistory: UserDeviceSettings["hide_notes_history"]) => {
      updateSettings({
        hide_notes_history: hideNotesHistory,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentHideNotesHistory,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <div className={styles.toggleRow}>
      <Label htmlFor={id}>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Label>
      <Toggle
        id={id}
        checked={value}
        onChange={onChange}
      />
    </div>
  )
}
