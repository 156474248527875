import {
  Legend,
  InfoButton,
  FieldSet,
  SelectButtons,
  SelectButton,
} from "@tandem-mobile/react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Ranges.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useGlucoseUnit,
} from "src/hooks"
import type {
  Settings,
  Account,
} from "src/types"
import { GlucoseUnit } from "src/types"

const polyglotPrefix = "pages.settings.ranges.glucose_units."

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function GlucoseUnits(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentGlucoseUnit = useGlucoseUnit()

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.value as GlucoseUnit,
    [],
  )

  const onValueChange = useCallback(
    (glucoseUnit: Account["units"]) => {
      updateSettings({
        units: glucoseUnit,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentGlucoseUnit,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <FieldSet>
      <Legend>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}label`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Legend>
      <SelectButtons className={styles.selectContainer}>
        <SelectButton
          htmlFor="glucose-unit-mgdl"
          name="glucose-unit"
          value={GlucoseUnit.MilligramsPerDeciliter}
          checked={value === GlucoseUnit.MilligramsPerDeciliter}
          onChange={onChange}
        >
          {polyglot.t(`${polyglotPrefix}options.mgdl`)}
        </SelectButton>
        <SelectButton
          htmlFor="glucose-unit-mmoll"
          name="glucose-unit"
          value={GlucoseUnit.MillimolesPerLiter}
          checked={value === GlucoseUnit.MillimolesPerLiter}
          onChange={onChange}
        >
          {polyglot.t(`${polyglotPrefix}options.mmoll`)}
        </SelectButton>
      </SelectButtons>
    </FieldSet>
  )
}
