import {
  Input,
  Label,
  InfoButton,
  ValidationContainer,
  ValidationMessage,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"
import styles from "./Reports.module.scss"
import { usePolyglot } from "src/contexts"
import type { Report } from "src/types"

const id = "email-input-id"
const polyglotPrefix = "pages.generate_report.email."

interface Props {
  report: Report;
  updateReport: (report: Partial<Report>) => void;
}

export function EmailInput(props: Props) {
  const {
    updateReport,
    report,
  } = props

  const polyglot = usePolyglot()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateReport({
        email: e.target.value,
      })
    },
    [updateReport],
  )

  return (
    <div>
      <Label htmlFor={id}>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <ValidationContainer className={styles.inputContainer}>
        <Input
          className={styles.emailInput}
          value={report.email}
          onChange={onChange}
          type="email"
          id={id}
          autoComplete="email"
          required
        />
        <ValidationMessage
          stylePreset="absolute"
          invalidMessage={{
            valueMissing: polyglot.t("validation_message.value_missing"),
            typeMismatch: polyglot.t("validation_message.email_type_mismatch"),
          }}
        />
      </ValidationContainer>
    </div>
  )
}
