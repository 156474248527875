import { BedtimeFillIcon } from "@tandem-mobile/react-components"
import { useCalculationsContext } from "../CalculationsContext"
import styles from "./Tile.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import {
  useDisplayGlucoseValue,
  useReadableGlucoseUnit,
} from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseAverageReadingBedtime(props: Props) {
  const { range } = props

  const displayGlucoseValue = useDisplayGlucoseValue()
  const glucoseUnit = useReadableGlucoseUnit()

  const { glucoseCalculations } = useCalculationsContext()
  const { bedTimeAverage } = glucoseCalculations[range] || {}

  const value = (isNaN(bedTimeAverage) || bedTimeAverage === undefined)
    ? "--"
    : displayGlucoseValue(bedTimeAverage)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseAverageReadingBedtime}>
        <BedtimeFillIcon className={styles.titleIcon} />
      </TileTitle>
      <TileTimeSpan range={range} />
      <TileValue>
        {value}
      </TileValue>
      <TileUnit>
        {glucoseUnit}
      </TileUnit>
    </>
  )
}
