import {
  InfoButton,
  Label,
  Input,
} from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Ranges.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"
import {
  sleepHoursToTime,
  timeToSleepHours,
} from "src/utils"

const timeInputFormat = "HH:mm"
const polyglotPrefix = "pages.settings.ranges.sleep_hours."

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function SleepHours(props: Props) {
  const {
    updateSettings,
    updatedAt,
  } = props

  const polyglot = usePolyglot()
  const currentSleepBottom = useDeviceSettingsProperty("sleep_bottom")
  const currentSleepTop = useDeviceSettingsProperty("sleep_top")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>): number => {
      const [
        hours,
        minutes,
      ] = e.target.value.split(":")

      const numMinutes = Number(minutes)
      const remainder = numMinutes % 30

      const roundMinutes = remainder >= 15
        ? numMinutes + (30 - remainder)
        : numMinutes - remainder

      const time = dayjs().startOf("day")
        .add(
          Number(hours),
          "hour",
        )
        .add(
          roundMinutes,
          "minute",
        )

      const newValue = timeToSleepHours(time.toISOString())
      return newValue
    },
    [],
  )

  const onBottomValueChange = useCallback(
    (sleepBottom: UserDeviceSettings["sleep_bottom"]) => {
      updateSettings({
        sleep_bottom: sleepBottom,
      })
    },
    [updateSettings],
  )

  const onTopValueChange = useCallback(
    (sleepTop: UserDeviceSettings["sleep_top"]) => {
      updateSettings({
        sleep_top: sleepTop,
      })
    },
    [updateSettings],
  )

  const {
    value: bottomValue,
    onChange: bottomOnChange,
  } = useInputPropsForValue({
    value: currentSleepBottom,
    onChange: onBottomValueChange,
    updatedAt,
    adapter,
  })

  const {
    value: topValue,
    onChange: topOnChange,
  } = useInputPropsForValue({
    value: currentSleepTop,
    onChange: onTopValueChange,
    updatedAt,
    adapter,
  })

  return (
    <div>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          <div>
            {polyglot.t(`${polyglotPrefix}description_one`)}
          </div>
          <div>
            {polyglot.t(`${polyglotPrefix}description_two`)}
          </div>
        </InfoButton>
      </Label>
      <div className={styles.inputContainer}>
        <Input
          step={60 * 30}
          className={styles.sleepInput}
          type="time"
          id="sleep-time-bottom"
          value={dayjs(sleepHoursToTime(bottomValue)).format(timeInputFormat)}
          onChange={bottomOnChange}
        />
        <div className={styles.sleepInputSeparator}>
          –
        </div>
        <Input
          step={60 * 30}
          className={styles.sleepInput}
          type="time"
          id="sleep-time-top"
          value={dayjs(sleepHoursToTime(topValue)).format(timeInputFormat)}
          onChange={topOnChange}
        />
      </div>
    </div>
  )
}
