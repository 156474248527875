import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
  Popover,
  PopoverTrigger,
  PopoverMenu,
  PopoverMenuItem,
  PopoverMenuItemSeparator,
} from "@tandem-mobile/react-components"
import {
  Fragment,
  useCallback,
} from "react"
import type { ChangeEventHandler } from "react"

import styles from "./ServingsRangeInput.module.scss"
import { usePolyglot } from "src/contexts"
import type { FoodSearchServing } from "src/types"

interface Props {
  value: number;
  setValue: (n: number) => void;
  servings: FoodSearchServing[];
  currentServingIndex: number;
  setCurrentServingIndex: (n: number) => void;
}

const id = "food-servings-range-input"
const min = 0
const max = 5
const step = 0.1
const polyglotPrefix = "components.food_search."

/*
 * this component renders a range input for selecting the number of servings of
 * a food, this value is multiplied against the nutrition data to
 * fill in the nutrition info
 */
export function ServingsRangeInput(props: Props) {
  const {
    value,
    setValue,
    servings,
    currentServingIndex,
    setCurrentServingIndex,
  } = props

  const polyglot = usePolyglot()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue(Number(e.target.value))
    },
    [setValue],
  )

  const currentServing = servings[currentServingIndex]

  return (
    <div className={styles.rangeInputContainer}>
      <SliderValue sizePreset="largePopover">
        <SliderValueInput
          min={min}
          max={max}
          value={value}
          step={step}
          onChange={onChange}
        />
        <SliderValueLabelContainer>
          <Popover placement="top-start">
            <PopoverTrigger asChild>
              <SliderValueLabel
                htmlFor={id}
                clickable
              >
                {polyglot.t(
                  `${polyglotPrefix}serving`,
                  {
                    quantity: currentServing.serving_qty,
                    unitOfMeasurement: currentServing.serving_uom,
                  },
                )}
              </SliderValueLabel>
            </PopoverTrigger>
            <PopoverMenu aria-label={polyglot.t(`${polyglotPrefix}serving_aria_label`)}>
              {servings.map(
                (serving: FoodSearchServing, index: number) => {
                  const onClick = () => {
                    setCurrentServingIndex(index)
                  }

                  const key = `${serving.serving_qty} ${serving.serving_uom}`

                  return (
                    <Fragment key={key}>
                      {index !== 0 && (
                        <PopoverMenuItemSeparator />
                      )}
                      <PopoverMenuItem
                        onClick={onClick}
                        key={key}
                        autoDismiss
                      >
                        {polyglot.t(
                          `${polyglotPrefix}serving`,
                          {
                            quantity: serving.serving_qty,
                            unitOfMeasurement: serving.serving_uom,
                          },
                        )}
                      </PopoverMenuItem>
                    </Fragment>
                  )
                },
              )}
            </PopoverMenu>
          </Popover>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          min={min}
          max={max}
          step={step}
          id={id}
          value={value}
          onChange={onChange}
        />
        <SliderTracks
          min={min}
          max={max}
        />
      </Slider>
    </div>
  )
}
