import {
  InputWithIconButton,
  InputIconButton,
  Input,
  CrossMarkIcon,
  MagnifyingGlassIcon,
} from "@tandem-mobile/react-components"
import classNames from "classnames"
import type {
  ChangeEventHandler,
  MouseEventHandler,
} from "react"
import { useCallback } from "react"
import styles from "./FavoritesSearchInput.module.scss"
import { usePolyglot } from "src/contexts"

interface Props {
  textValue: string;
  setTextValue: (s: string) => void;
}

/*
 * this component renders the text input where the user types the search term
 */
export function FavoritesSearchInput(props: Props) {
  const {
    textValue,
    setTextValue,
  } = props

  const polyglot = usePolyglot()

  const onTextChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setTextValue(e.target.value)
    },
    [setTextValue],
  )

  const clearInput: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      setTextValue("")
    },
    [setTextValue],
  )

  const clearButtonClassName = classNames({
    [styles.hiddenClearButton]: !textValue,
  })

  return (
    <InputWithIconButton>
      <InputIconButton
        placementPreset="start"
      >
        <MagnifyingGlassIcon />
      </InputIconButton>
      <Input
        value={textValue}
        onChange={onTextChange}
        placeholder={polyglot.t("components.favorites_modal.search_placeholder")}
      />
      <InputIconButton
        className={clearButtonClassName}
        placementPreset="end"
        onClick={clearInput}
      >
        <CrossMarkIcon />
      </InputIconButton>
    </InputWithIconButton>
  )
}
