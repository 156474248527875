import {
  ActionSubmit,
  Form,
  Checkbox,
  CheckboxCheckMark,
  LinkOutlineIcon,
} from "@tandem-mobile/react-components"
import type {
  FormEventHandler,
  ChangeEventHandler,
} from "react"
import {
  useCallback,
  useState,
} from "react"
import type { PrivacyFormData } from "./PrivacyFormData"
import { ResidenceSelect } from "./ResidenceSelect"
import styles from "./SignUp.module.scss"
import { usePolyglot } from "src/contexts"
import { GlucoseUnit } from "src/types"

const termsOfUseId = "terms-of-use-input"
const privacyPolicyId = "privacy-policy-input"
const polyglotPrefix = "pages.sign_up.new_user_privacy."

interface Props {
  onSubmit: (data: PrivacyFormData) => void;
}

export function NewUserPrivacyForm(props: Props) {
  const polyglot = usePolyglot()

  const [
    formState,
    setFormState,
  ] = useState<PrivacyFormData>({
    termsOfUse: false,
    privacyPolicy: false,
    country: "",
    units: GlucoseUnit.MilligramsPerDeciliter,
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      props.onSubmit(formState)
    },
    [
      props.onSubmit,
      formState,
    ],
  )

  const onTermsOfUseChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        termsOfUse: e.target.checked,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onPrivacyPolicyChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        privacyPolicy: e.target.checked,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  return (
    <Form
      className={styles.form}
      onSubmit={onSubmit}
    >
      <div className={styles.description}>
        <div>
          {polyglot.t(`${polyglotPrefix}disclaimer.part_one`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}disclaimer.part_two`)}
        </div>
      </div>
      <div
        className={styles.privacyCheckboxContainer}
        data-checked={formState.termsOfUse}
      >
        <Checkbox
          className={styles.checkbox}
          htmlFor={termsOfUseId}
          checked={formState.termsOfUse}
          onChange={onTermsOfUseChange}
          required
        >
          <CheckboxCheckMark />
          {polyglot.t(`${polyglotPrefix}terms_of_use.label`)}
        </Checkbox>
        <a
          href="https://sugarmate.io/tos"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <LinkOutlineIcon className={styles.linkIcon} />
        </a>
      </div>
      <div
        className={styles.privacyCheckboxContainer}
        data-checked={formState.privacyPolicy}
      >
        <Checkbox
          className={styles.checkbox}
          htmlFor={privacyPolicyId}
          checked={formState.privacyPolicy}
          onChange={onPrivacyPolicyChange}
          required
        >
          <CheckboxCheckMark />
          {polyglot.t(`${polyglotPrefix}privacy_policy.label`)}
        </Checkbox>
        <a
          href="https://sugarmate.io/privacy"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <LinkOutlineIcon className={styles.linkIcon} />
        </a>
      </div>
      <ResidenceSelect
        formState={formState}
        setFormState={setFormState}
      />
      <ActionSubmit
        value={polyglot.t(`${polyglotPrefix}submit_cta`)}
        stylePreset="primary"
        className={styles.next}
      />
    </Form>
  )
}
