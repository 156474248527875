import { ActionLink } from "@tandem-mobile/react-components"
import { Link } from "@tanstack/react-router"

import { OnboardingPageHeader } from "../OnboardingPageHeader"
import styles from "./Ranges.module.scss"
import { Page } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useDebouncedUpdater,
  useDeviceProperty,
  useAccountProperty,
  useUpdateSettings,
} from "src/hooks"
import { GlucoseUnits } from "src/pages/Settings/Ranges/GlucoseUnits"
import { NormalRange } from "src/pages/Settings/Ranges/NormalRange"
import sharedStyles from "src/pages/Settings/Ranges/Ranges.module.scss"
import { SleepHours } from "src/pages/Settings/Ranges/SleepHours"
import { UrgentLow } from "src/pages/Settings/Ranges/UrgentLow"

const polyglotPrefix = "pages.onboarding.ranges."

export function Ranges() {
  const polyglot = usePolyglot()

  const deviceUpdatedAt = useDeviceProperty(
    "updated_at",
    undefined,
  )

  const accountUpdatedAt = useAccountProperty(
    "updated_at",
    undefined,
  )

  const country = useAccountProperty(
    "country",
    undefined,
  )

  // TODO catch error and show toast
  const updateSettings = useUpdateSettings()
  const debouncedUpdateSettings = useDebouncedUpdater(updateSettings)

  return (
    <>
      <OnboardingPageHeader backRoutePath="/onboarding/data_source">
        {polyglot.t(`${polyglotPrefix}title`)}
      </OnboardingPageHeader>
      <Page>
        <div className={sharedStyles.container}>
          <NormalRange
            updatedAt={deviceUpdatedAt}
            updateSettings={debouncedUpdateSettings}
          />
          <UrgentLow
            updatedAt={deviceUpdatedAt}
            updateSettings={debouncedUpdateSettings}
          />
          {country !== "US" && (
            <GlucoseUnits
              updatedAt={accountUpdatedAt}
              updateSettings={debouncedUpdateSettings}
            />
          )}
          <SleepHours
            updatedAt={deviceUpdatedAt}
            updateSettings={debouncedUpdateSettings}
          />
          <ActionLink
            asChild
            className={styles.finishCta}
          >
            <Link to="/home">
              {polyglot.t(`${polyglotPrefix}next_cta`)}
            </Link>
          </ActionLink>
        </div>
      </Page>
    </>
  )
}
