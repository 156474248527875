import { Toast } from "@tandem-mobile/react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
  email: string;
}

export function SendReportSuccessToast(props: Props) {
  const {
    id, email,
  } = props

  const polyglot = usePolyglot()

  return (
    <Toast
      stylePreset="success"
      body={polyglot.t(
        "toasts.send_report_success.body",
        { email },
      )}
      id={id}
    />
  )
}
