import {
  ActionButton,
  Modal,
  ModalBody,
  ModalButton,
  ModalButtons,
  ModalCloseX,
  ModalContent,
  ModalHeading,
} from "@tandem-mobile/react-components"
import type { ReactNode } from "react"
import {
  useCallback,
  useEffect,
  useState,
} from "react"

import { CaloriesRangeInput } from "./CaloriesRangeInput"
import { FatRangeInput } from "./FatRangeInput"
import { FiberRangeInput } from "./FiberRangeInput"
import styles from "./NutritionModal.module.scss"
import { ProteinRangeInput } from "./ProteinRangeInput"
import { SugarRangeInput } from "./SugarRangeInput"
import { DiscardChangesDialog } from "src/components/DiscardChangesDialog"
import { usePolyglot } from "src/contexts"
import { useEditableType } from "src/hooks"
import type { FoodActivity } from "src/types"

const polyglotPrefix = "components.entry_modal.food.nutrition_editor_modal."

interface Props {
  activity: FoodActivity;
  updateActivity: (a: FoodActivity) => void;
  children?: ReactNode;
}

export function NutritionModal(props: Props) {
  const {
    activity, updateActivity,
  } = props

  const polyglot = usePolyglot()
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)
  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)

  // current editor state of the food activity
  const {
    current: currentFoodActivity,
    hasChanged: foodActivityHasChanged,
    save: resetFoodActivity,
    update: editFoodActivity,
  } = useEditableType<FoodActivity>(activity)

  // every time the the modal closes reset currentFoodActivity
  useEffect(
    () => {
      if (!isOpen) {
        resetFoodActivity(activity)
      }
    },
    [
      activity,
      resetFoodActivity,
      isOpen,
    ],
  )

  // closes the modal regardless of whether or not changes were saved
  const closeModal = useCallback(
    () => {
      setIsOpen(false)
    },
    [setIsOpen],
  )

  // show the warning dialog if changes have been made without saving
  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        setIsOpen(true)
      } else if (foodActivityHasChanged) {
        setIsDiscardOpen(true)
      } else {
        setIsOpen(false)
      }
    },
    [
      foodActivityHasChanged,
      setIsOpen,
      setIsDiscardOpen,
    ],
  )

  const update = useCallback(
    () => {
      updateActivity(currentFoodActivity)
      closeModal()
    },
    [
      closeModal,
      currentFoodActivity,
      updateActivity,
    ],
  )

  return (
    <Modal open={isOpen}
      onOpenChange={onOpenChange}
    >
      {props.children}
      <ModalContent>
        <ModalHeading>
          {activity.title || polyglot.t(`${polyglotPrefix}title`)}
          <ModalCloseX />
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          <div className={styles.inputs}>
            <ProteinRangeInput
              activity={currentFoodActivity}
              updateActivity={editFoodActivity}
            />
            <FatRangeInput
              activity={currentFoodActivity}
              updateActivity={editFoodActivity}
            />
            <SugarRangeInput
              activity={currentFoodActivity}
              updateActivity={editFoodActivity}
            />
            <FiberRangeInput
              activity={currentFoodActivity}
              updateActivity={editFoodActivity}
            />
            <CaloriesRangeInput
              activity={currentFoodActivity}
              updateActivity={editFoodActivity}
            />
          </div>
          <ModalButtons>
            <ModalButton stylePreset="secondary">
              {polyglot.t(`${polyglotPrefix}cancel_cta`)}
            </ModalButton>
            <ActionButton
              className={styles.modalMultiButton}
              stylePreset="primary"
              disabled={!foodActivityHasChanged}
              onClick={update}
            >
              {polyglot.t(`${polyglotPrefix}update_cta`)}
            </ActionButton>
          </ModalButtons>
        </ModalBody>
        <DiscardChangesDialog
          open={isDiscardOpen}
          setOpen={setIsDiscardOpen}
          discardChanges={closeModal}
        />
      </ModalContent>
    </Modal>
  )
}
