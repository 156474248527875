import {
  ActionButton,
  Dialog,
  DialogHeading,
  DialogBody,
  DialogContent,
  DialogButtons,
  DialogButton,
  DialogTrigger,
  useToasterContext,
} from "@tandem-mobile/react-components"
import { useCallback } from "react"

import styles from "./DeleteAccount.module.scss"
import {
  usePolyglot,
  useAuthContext,
} from "src/contexts"
import { useDeleteAccount } from "src/hooks"
import { DeleteAccountErrorToast } from "src/toasts"

const polyglotPrefix = "pages.settings.account.delete_account."

export function DeleteAccount() {
  const polyglot = usePolyglot()
  const deleteAccount = useDeleteAccount()
  const { showToast } = useToasterContext()
  const { signOut } = useAuthContext()

  const onDeleteClick = useCallback(
    () => {
      void (async () => {
        try {
          await deleteAccount()
          await signOut()
        } catch (e) {
          showToast(
            "delete-account-error-toast",
            DeleteAccountErrorToast,
          )
        }
      })()
    },
    [
      signOut,
      deleteAccount,
      showToast,
    ],
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <ActionButton stylePreset="destructive">
          {polyglot.t(`${polyglotPrefix}trigger_cta`)}
        </ActionButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}title`)}
        </DialogHeading>
        <DialogBody className={styles.dialogBody}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </DialogBody>
        <DialogButtons>
          <DialogButton
            stylePreset="destructive"
            onClick={onDeleteClick}
          >
            {polyglot.t(`${polyglotPrefix}confirm_cta`)}
          </DialogButton>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
