import { useToasterContext } from "@tandem-mobile/react-components"
import { useNavigate } from "@tanstack/react-router"
import {
  useCallback,
  useState,
} from "react"

import { NewUserAccountForm } from "./NewUserAccountForm"
import { NewUserPrivacyForm } from "./NewUserPrivacyForm"
import type { PrivacyFormData } from "./PrivacyFormData"
import styles from "./SignUp.module.scss"
import { PublicPageHeader } from "src/components"
import {
  usePolyglot,
  useAuthContext,
  useSessionStorageContext,
} from "src/contexts"
import { SignInAfterSignUpErrorToast } from "src/toasts"

export function SignUp() {
  const [
    completedPrivacyForm,
    setCompletedPrivacyForm,
  ] = useState<PrivacyFormData | undefined>(undefined)

  const polyglot = usePolyglot()
  const { setLoginRedirect } = useSessionStorageContext()
  const { signIn } = useAuthContext()
  const { showToast } = useToasterContext()
  const navigate = useNavigate()

  const onPrivacyFormSubmit = useCallback(
    (privacyFormData: PrivacyFormData) => {
      setCompletedPrivacyForm(privacyFormData)
    },
    [setCompletedPrivacyForm],
  )

  const onAccountCreate = useCallback(
    async (
      email: string,
      password: string,
    ) => {
      setLoginRedirect("/onboarding/data_source")

      try {
        await signIn(
          email,
          password,
        )
      } catch (e) {
        showToast(
          "sign-in-after-sign-up-error-toast",
          SignInAfterSignUpErrorToast,
        )

        void navigate({ to: "/sign_in" })
      }
    },
    [
      setLoginRedirect,
      signIn,
      navigate,
    ],
  )

  const goBack = useCallback(
    () => {
      setCompletedPrivacyForm(undefined)
    },
    [],
  )

  return (
    <div className={styles.container}>
      {completedPrivacyForm
        ? (
          <NewUserAccountForm
            privacyFormData={completedPrivacyForm}
            goBack={goBack}
            onAccountCreate={onAccountCreate}
          />
        )
        : (
          <>
            <PublicPageHeader backRoutePath="/">
              {polyglot.t("pages.sign_up.new_user_privacy.title")}
            </PublicPageHeader>
            <NewUserPrivacyForm onSubmit={onPrivacyFormSubmit} />
          </>
        )
      }
    </div>
  )
}
