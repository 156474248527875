import {
  ActionButton,
  ActionSubmit,
  Modal,
  ModalBody,
  ModalButton,
  ModalButtons,
  ModalCloseX,
  ModalContentForm,
  ModalHeading,
  useToasterContext,
} from "@tandem-mobile/react-components"
import {
  useCallback,
  useMemo,
  useState,
} from "react"

import type {
  ReactNode,
  MouseEventHandler,
  FormEventHandler,
} from "react"

import { DiscardChangesDialog } from "../DiscardChangesDialog"
import { RemoveDialog } from "../RemoveDialog"
import styles from "./AccountMedicationModal.module.scss"
import { AccountMedicationModalContext } from "./AccountMedicationModalContext"
import type { EditableAccountMedication } from "./EditableAccountMedication"
import { emptyAccountMedication } from "./EditableAccountMedication"
import { MedicationCategorySelect } from "./MedicationCategorySelect"
import { MedicationNameSelect } from "./MedicationNameSelect"
import { MedicationUnitsInput } from "./MedicationUnitsInput"
import { usePolyglot } from "src/contexts"
import {
  useSaveAccountMedication,
  useDeleteAccountMedication,
  useEditableType,
  useMedication,
  useLoadMedicationsCollection,
  useLoadAccountMedicationsCollection, // TODO move this
} from "src/hooks"
import {
  AddMedicationErrorToast,
  AddMedicationSuccessToast,
  EditMedicationErrorToast,
  EditMedicationSuccessToast,
  RemoveMedicationErrorToast,
  RemoveMedicationSuccessToast,
} from "src/toasts"

const polyglotPrefix = "components.account_medication_modal."

interface Props {
  children: ReactNode;
}

export function AccountMedicationModal(props: Props) {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)
  const [
    isRemoveOpen,
    setIsRemoveOpen,
  ] = useState<boolean>(false)
  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState<boolean>(false)

  useLoadMedicationsCollection()
  useLoadAccountMedicationsCollection()

  const {
    current: currentAccountMedication,
    hasChanged: accountMedicationHasChanged,
    save: resetAccountMedication,
    update: editAccountMedication,
  } = useEditableType<EditableAccountMedication>(emptyAccountMedication)

  const currentMedication = useMedication(currentAccountMedication.med_id)

  // closes the modal regardless of whether or not changes were saved
  const closeModal = useCallback(
    () => {
      setIsModalOpen(false)
    },
    [setIsModalOpen],
  )

  // opens the modal and fills it w/ the specified account medication
  const openModal = useCallback(
    (accountMedication: EditableAccountMedication = emptyAccountMedication) => {
      resetAccountMedication(accountMedication)
      setIsModalOpen(true)
    },
    [
      setIsModalOpen,
      resetAccountMedication,
    ],
  )

  // show the warning dialog if changes have been made without saving
  // the only way to open this modal is with "openAccountMedicationModal"
  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        if (accountMedicationHasChanged) {
          setIsDiscardOpen(true)
        } else {
          setIsModalOpen(false)
        }
      }
    },
    [
      accountMedicationHasChanged,
      setIsModalOpen,
      setIsDiscardOpen,
    ],
  )

  // will either create or update the account medication
  const saveAccountMedication = useSaveAccountMedication()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          await saveAccountMedication(currentAccountMedication)
          closeModal()

          if (currentAccountMedication.id) {
            showToast(
              "edit-medication-success-toast",
              EditMedicationSuccessToast,
            )
          } else {
            showToast(
              "add-medication-success-toast",
              AddMedicationSuccessToast,
            )
          }
        } catch (e) {
          if (currentAccountMedication.id) {
            showToast(
              "edit-medication-error-toast",
              EditMedicationErrorToast,
            )
          } else {
            showToast(
              "add-medicatoin-error-toast",
              AddMedicationErrorToast,
            )
          }
        }
      })()
    },
    [
      showToast,
      closeModal,
      saveAccountMedication,
      currentAccountMedication,
    ],
  )

  // will remove the account medication
  const removeAccountMedication = useDeleteAccountMedication()

  const remove: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      void (async () => {
        if (!currentAccountMedication.id) {
          throw new Error("cannot delete account insulin that has not been saved")
        }

        try {
          await removeAccountMedication(currentAccountMedication.id)

          showToast(
            "remove-medication-success-toast",
            RemoveMedicationSuccessToast,
          )

          closeModal()
        } catch (e) {
          showToast(
            "remove-medication-error-toast",
            RemoveMedicationErrorToast,
          )
        }
      })()
    },
    [
      showToast,
      closeModal,
      removeAccountMedication,
      currentAccountMedication,
    ],
  )

  // when the remove cta is clicked, open the remove dialog
  const onRemoveClick = useCallback(
    () => {
      setIsRemoveOpen(true)
    },
    [setIsRemoveOpen],
  )

  const isNewAccountMedication = currentAccountMedication.id === emptyAccountMedication.id

  const contextValue = useMemo(
    () => ({
      openAccountMedicationModal: openModal,
    }),
    [openModal],
  )

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={onOpenChange}
    >
      <AccountMedicationModalContext.Provider value={contextValue}>
        {props.children}
      </AccountMedicationModalContext.Provider>
      <ModalContentForm onSubmit={onSubmit}>
        <ModalHeading>
          {isNewAccountMedication
            ? polyglot.t(`${polyglotPrefix}add_title`)
            : polyglot.t(
              `${polyglotPrefix}edit_title`,
              { name: currentMedication?.name },
            )
          }
          <ModalCloseX />
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          {isNewAccountMedication && (
            <MedicationCategorySelect
              currentAccountMedication={currentAccountMedication}
              editAccountMedication={editAccountMedication}
            />
          )}
          {isNewAccountMedication && (
            <MedicationNameSelect
              currentAccountMedication={currentAccountMedication}
              editAccountMedication={editAccountMedication}
            />
          )}
          <MedicationUnitsInput
            currentAccountMedication={currentAccountMedication}
            editAccountMedication={editAccountMedication}
          />
          <ModalButtons >
            {isNewAccountMedication ? (
              <ModalButton
                stylePreset="secondary"
                type="button"
              >
                {polyglot.t(`${polyglotPrefix}cancel_cta`)}
              </ModalButton>
            ) : (
              <ActionButton
                className={styles.modalMultiButton}
                stylePreset="secondary"
                onClick={onRemoveClick}
                type="button"
              >
                {polyglot.t(`${polyglotPrefix}remove_cta`)}
              </ActionButton>
            )}
            <ActionSubmit
              className={styles.modalMultiButton}
              stylePreset="primary"
              value={polyglot.t(`${polyglotPrefix}save_cta`)}
              disabled={!accountMedicationHasChanged}
            />
          </ModalButtons>
        </ModalBody>
        <DiscardChangesDialog
          open={isDiscardOpen}
          setOpen={setIsDiscardOpen}
          discardChanges={closeModal}
        />
        <RemoveDialog
          open={isRemoveOpen}
          setOpen={setIsRemoveOpen}
          remove={remove}
          title={polyglot.t(
            `${polyglotPrefix}remove_heading`,
            { name: currentMedication?.name },
          )}
        />
      </ModalContentForm>
    </Modal>
  )
}
