import {
  ActionSubmit,
  Form,
  useToasterContext,
} from "@tandem-mobile/react-components"
import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react"
import type { FormEventHandler } from "react"

import { DateRangeInput } from "./DateRangeInput"
import { EmailInput } from "./EmailInput"
import styles from "./Reports.module.scss"
import { ShowPhotosToggle } from "./ShowPhotosToggle"
import { useSendReport } from "./useSendReport"
import {
  PageHeader,
  Page,
} from "src/components"
import { usePolyglot } from "src/contexts"
import { useAccountProperty } from "src/hooks"
import {
  SendReportErrorToast,
  SendReportSuccessToast,
} from "src/toasts"
import type { Report } from "src/types"
import {
  getTimezoneOffset,
  injectProps,
} from "src/utils"

const polyglotPrefix = "pages.generate_report."

export function Reports() {
  const polyglot = usePolyglot()
  const email = useAccountProperty(
    "email",
    "",
  )
  const sendReport = useSendReport()
  const { showToast } = useToasterContext()

  const [
    sending,
    setSending,
  ] = useState<boolean>(false)

  const currentReportRef = useRef<Report>({
    email,
    start_date: "",
    end_date: "",
    show_photos: false,
    tz: getTimezoneOffset(),
  })

  const [
    currentReport,
    setCurrentReport,
  ] = useState<Report>(currentReportRef.current)

  const updateCurrentReport = useCallback(
    (report: Partial<Report>) => {
      currentReportRef.current = {
        ...currentReportRef.current,
        ...report,
      }

      setCurrentReport(currentReportRef.current)
    },
    [
      currentReportRef,
      setCurrentReport,
    ],
  )

  // if the email comes in and the email field is currently blank, update it
  useEffect(
    () => {
      if (!!email && !currentReportRef.current.email) {
        updateCurrentReport({
          email,
        })
      }
    },
    [
      email,
      currentReportRef,
      updateCurrentReport,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        setSending(true)

        try {
          const response = await sendReport({
            email: currentReport.email,
            start_date: currentReport.start_date,
            end_date: currentReport.end_date,
            show_photos: currentReport.show_photos,
            tz: currentReport.tz,
          })

          // TODO we need to document error codes
          if (response.data.status === "fail") {
            throw new Error()
          }

          const SuccessToast = injectProps(
            SendReportSuccessToast,
            { email: currentReport.email },
          )

          showToast(
            "send-report-success-toast",
            SuccessToast,
          )
        } catch (e) {
          showToast(
            "send-report-error-toast",
            SendReportErrorToast,
          )
        }

        setSending(false)
      })()
    },
    [
      currentReport,
      sendReport,
      setSending,
      showToast,
    ],
  )

  return (
    <>
      <PageHeader />
      <Page>
        <Form
          className={styles.form}
          onSubmit={onSubmit}
        >
          <EmailInput
            report={currentReport}
            updateReport={updateCurrentReport}
          />
          <DateRangeInput
            report={currentReport}
            updateReport={updateCurrentReport}
          />
          <ShowPhotosToggle
            report={currentReport}
            updateReport={updateCurrentReport}
          />
          <ActionSubmit
            className={styles.submit}
            stylePreset="primary"
            value={sending
              ? polyglot.t(`${polyglotPrefix}sending_cta`)
              : polyglot.t(`${polyglotPrefix}send_cta`)
            }
            disabled={sending}
          />
        </Form>
      </Page>
    </>
  )
}
