import {
  Modal,
  ModalBody,
  ModalCloseX,
  ModalContent,
  ModalHeading,
  StarOutlineIcon,
} from "@tandem-mobile/react-components"
import { debounce } from "lodash-es"
import {
  useCallback,
  useMemo,
  useState,
} from "react"
import type { ReactNode } from "react"

import { DeleteFavoriteDialog } from "./DeleteFavoriteDialog"
import { EditFavoriteDialog } from "./EditFavoriteDialog"
import { FavoriteMenuItem } from "./FavoriteMenuItem"
import styles from "./FavoritesModal.module.scss"
import { FavoritesModalContext } from "./FavoritesModalContext"
import { FavoritesSearchInput } from "./FavoritesSearchInput"
import { usePolyglot } from "src/contexts"
import { useSearchEntryTemplates } from "src/hooks"

const polyglotPrefix = "components.favorites_modal."

interface Props {
  children: ReactNode;
}

export function FavoritesModal(props: Props) {
  const polyglot = usePolyglot()

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState<boolean>(false)

  const [
    creationTime,
    setCreationTime,
  ] = useState<string | undefined>()

  const [
    inputText,
    setInputText,
  ] = useState<string>("")

  const [
    searchText,
    setSearchText,
  ] = useState<string>("")

  const updateSearchText = useCallback(
    debounce(
      (text: string) => {
        setSearchText(text)
      },
      1000,
    ),
    [setSearchText],
  )

  const onInputTextChange = useCallback(
    (text: string) => {
      setInputText(text)
      updateSearchText(text)
    },
    [
      setInputText,
      updateSearchText,
    ],
  )

  const { result } = useSearchEntryTemplates(searchText)

  const openFavoritesModal = useCallback(
    (time?: string) => {
      setIsModalOpen(true)
      setCreationTime(time)
    },
    [
      setIsModalOpen,
      setCreationTime,
    ],
  )

  const contextValue = useMemo(
    () => ({
      openFavoritesModal,
    }),
    [openFavoritesModal],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      setIsModalOpen(newOpen)
    },
    [setIsModalOpen],
  )

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={onOpenChange}
    >
      <FavoritesModalContext.Provider value={contextValue}>
        {props.children}
      </FavoritesModalContext.Provider>
      <ModalContent>
        <DeleteFavoriteDialog>
          <EditFavoriteDialog>
            <ModalHeading>
              <div>
                <StarOutlineIcon
                  className={styles.headingIcon}
                />
                {polyglot.t(`${polyglotPrefix}title`)}
              </div>
              <ModalCloseX />
            </ModalHeading>
            <ModalBody className={styles.modalBody}>
              <FavoritesSearchInput
                textValue={inputText}
                setTextValue={onInputTextChange}
              />
              <div className={styles.entryTemplatesList}>
                {result.map(
                  (entryTemplate) => (
                    <FavoriteMenuItem
                      key={entryTemplate.id}
                      entryTemplate={entryTemplate}
                      creationTime={creationTime}
                    />
                  ),
                )}
              </div>
            </ModalBody>
          </EditFavoriteDialog>
        </DeleteFavoriteDialog>
      </ModalContent>
    </Modal>
  )
}
