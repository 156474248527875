import {
  Modal,
  ModalContentForm,
  ModalBody,
  ModalHeading,
  ModalCloseX,
  ActionSubmit,
  Dropdown,
  DropdownCaret,
  DropdownOption,
  DropdownSelect,
  useToasterContext,
  DropdownCurrentSelection,
} from "@tandem-mobile/react-components"
import type {
  ChangeEventHandler,
  FormEventHandler,
  ReactNode,
} from "react"
import {
  useState,
  useCallback,
  useMemo,
} from "react"
import { defaultInsightRangeByKey } from "../defaultInsightRangeByKey"
import { insightRangesByKey } from "../insightRangesByKey"
import { InsightRangeString } from "../InsightRangeString"
import { useInsightIcon } from "../useInsightIcon"
import styles from "./EditInsightModal.module.scss"
import { EditInsightModalContext } from "./EditInsightModalContext"
import { DiscardChangesDialog } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useUpdateInsight,
  useEditableType,
} from "src/hooks"
import { EditInsightErrorToast } from "src/toasts"
import type {
  Insight,
  InsightRange,
} from "src/types"
import { InsightKey } from "src/types"
import { injectProps } from "src/utils"

const polyglotPrefix = "pages.home.insights."

interface Props {
  children: ReactNode;
  availableInsightRangesByKey: Record<InsightKey, Array<InsightRange>>;
}

export function EditInsightModal(props: Props) {
  const polyglot = usePolyglot()
  const updateInsight = useUpdateInsight()
  const { showToast } = useToasterContext()

  const {
    original: originalInsight,
    current: currentInsight,
    hasChanged: insightHasChanged,
    save: resetCurrentInsight,
    update: setCurrentInsight,
  } = useEditableType<Insight>({
    key: InsightKey.GlucoseAverageReading,
    range: insightRangesByKey[InsightKey.GlucoseAverageReading][0],
  })

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState<boolean>(false)

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)

  const InsightIcon = useInsightIcon(currentInsight.key)

  const openEditInsightModal = useCallback(
    (insight: Insight) => {
      resetCurrentInsight(insight)
      setIsModalOpen(true)
    },
    [
      setIsModalOpen,
      resetCurrentInsight,
    ],
  )

  const closeEditInsightModal = useCallback(
    () => {
      setIsModalOpen(false)
    },
    [setIsModalOpen],
  )

  const contextValue = useMemo(
    () => ({
      openEditInsightModal,
    }),
    [openEditInsightModal],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        const ToastComponent = injectProps(
          EditInsightErrorToast,
          { insightKey: currentInsight.key },
        )

        try {
          await updateInsight(
            originalInsight,
            currentInsight,
          )
          setIsModalOpen(false)
        } catch (e) {
          showToast(
            "edit-insight-error-toast",
            ToastComponent,
          )
        }
      })()
    },
    [
      currentInsight,
      originalInsight,
      updateInsight,
      showToast,
    ],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        if (insightHasChanged) {
          setIsDiscardOpen(true)
        } else {
          closeEditInsightModal()
        }
      }
    },
    [
      insightHasChanged,
      setIsDiscardOpen,
      closeEditInsightModal,
    ],
  )

  const onRangeChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const range = Number(e.target.value) as InsightRange

      setCurrentInsight({
        ...currentInsight,
        range,
      })
    },
    [currentInsight],
  )

  const availableInsightRanges = props.availableInsightRangesByKey[currentInsight.key]
  const allInsightRanges = insightRangesByKey[currentInsight.key]

  const rangeDisabledMap: Record<InsightRange, boolean> = useMemo(
    () => {
      const map: Record<InsightRange, boolean> = {}

      allInsightRanges.forEach(
        (insightRange) => {
          map[insightRange] = !availableInsightRanges.includes(insightRange)
        },
      )

      return map
    },
    [
      availableInsightRanges,
      allInsightRanges,
    ],
  )

  // some tiles that can only have one range won't have a range set, so we use the default range
  const currentRange = currentInsight.range || defaultInsightRangeByKey[currentInsight.key]

  return (
    <EditInsightModalContext.Provider value={contextValue}>
      <Modal
        open={isModalOpen}
        onOpenChange={onOpenChange}
      >
        {props.children}
        <ModalContentForm onSubmit={onSubmit}>
          <ModalHeading>
            <div className={styles.modalHeading}>
              {InsightIcon && <InsightIcon className={styles.modalHeadingIcon} />}
              {polyglot.t(`${polyglotPrefix}cta_label.${currentInsight.key}`)}
            </div>
            <ModalCloseX />
          </ModalHeading>
          <ModalBody className={styles.modalBody}>
            {polyglot.t(`${polyglotPrefix}cta_subtext.${currentInsight.key}`)}
            <Dropdown>
              <DropdownSelect
                value={`${currentRange}`}
                onChange={onRangeChange}
                required
              >
                {allInsightRanges.map(
                  (insightRange) => {
                    const checked = insightRange === currentRange
                    const wasChecked = insightRange === originalInsight.range
                    const disabled = !checked && !wasChecked && rangeDisabledMap[insightRange]

                    return (
                      <DropdownOption
                        key={`insight-range-${insightRange}`}
                        value={`${insightRange}`}
                        disabled={disabled}
                      >
                        <InsightRangeString range={insightRange} />
                      </DropdownOption>
                    )
                  },
                )}
              </DropdownSelect>
              <DropdownCurrentSelection />
              <DropdownCaret />
            </Dropdown>
            <ActionSubmit
              value= {polyglot.t(`${polyglotPrefix}edit_insight_modal.submit_cta`)}
              disabled={!insightHasChanged}
            />
          </ModalBody>
          <DiscardChangesDialog
            open={isDiscardOpen}
            setOpen={setIsDiscardOpen}
            discardChanges={closeEditInsightModal}
          />
        </ModalContentForm>
      </Modal>
    </EditInsightModalContext.Provider>
  )
}
