import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
  InfoButton,
  Label,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
} from "react"

import styles from "./AccountInsulinModal.module.scss"
import type { EditableAccountInsulin } from "./EditableAccountInsulin"
import { emptyAccountInsulin } from "./EditableAccountInsulin"
import { usePolyglot } from "src/contexts"
import { useInsulinsInCategory } from "src/hooks"

interface Props {
  currentAccountInsulin: EditableAccountInsulin;
  editAccountInsulin: (e: EditableAccountInsulin) => void;
}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const id = "insulin_name_select"
const polyglotPrefix = "components.account_insulin_modal.name."

/*
 * renders a select element for choosing insulin name
 */
export function InsulinNameSelect(props: Props) {
  const {
    currentAccountInsulin,
    editAccountInsulin,
  } = props

  const polyglot = usePolyglot()

  // insulins in category alphabetized by name
  const insulins = useInsulinsInCategory(currentAccountInsulin.insulin?.category ?? "")

  // the options to present in the select menu
  const options: Array<Option> = useMemo(
    () => {
      const optionsArray: Array<Option> = [
        // the placeholder option
        {
          value: `${emptyAccountInsulin.insulin_id}`,
          children: polyglot.t(`${polyglotPrefix}placeholder`),
          disabled: true,
        },
      ]

      // add each insulin to the options menu
      insulins.forEach((insulin) => {
        const phraseKey = `${polyglotPrefix}${insulin.name}`

        // value is the insulin id but the display value is the insulin name
        optionsArray.push({
          value: insulin.id,
          // TODO we are not yet localizing insulin names
          children: polyglot.has(phraseKey)
            ? polyglot.t(phraseKey)
            : (insulin.name ?? ""),
        })
      })

      return optionsArray
    },
    [insulins],
  )

  // update the currentAccountInsulin's insulin_id
  const onInsulinChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const insulinId = e.target.value

      // update insulin id
      editAccountInsulin({
        ...currentAccountInsulin,
        insulin_id: insulinId,
      })
    },
    [
      currentAccountInsulin,
      editAccountInsulin,
    ],
  )

  return (
    <div>
      <Label htmlFor={id}
        sizePreset="small"
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Dropdown>
        <DropdownSelect
          id={id}
          disabled={(currentAccountInsulin.insulin?.category ?? "") === emptyAccountInsulin.insulin.category}
          value={currentAccountInsulin.insulin_id ?? ""}
          onChange={onInsulinChange}
          required
        >
          {options.map(
            (selectOption) => (
              <DropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </DropdownSelect>
        <DropdownCurrentSelection />
        <DropdownCaret />
      </Dropdown>
    </div>
  )
}
