import {
  FieldSet,
  Legend,
  SelectionButton,
  SelectionButtons,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type { DeviceChangeActivity } from "src/types"
import { DeviceType } from "src/types"

const polyglotPrefix = "components.entry_modal.device_change."

interface Props {
  activity: DeviceChangeActivity;
  updateActivity: (a: DeviceChangeActivity) => void;
}

export function DeviceTypeRadioButton(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  const onDeviceTypeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = e.target.value as DeviceType

      updateActivity({
        device_type: newValue,
      })
    },
    [updateActivity],
  )

  return (
    <FieldSet>
      <Legend className={styles.legend}
        sizePreset="small"
      >
        {polyglot.t(`${polyglotPrefix}legend`)}
      </Legend>
      <SelectionButtons>
        <SelectionButton
          htmlFor={DeviceType.Sensor}
          value={DeviceType.Sensor}
          name="device_type"
          onChange={onDeviceTypeChange}
          checked={activity.device_type === DeviceType.Sensor}
        >
          {polyglot.t(`${polyglotPrefix}sensor`)}
        </SelectionButton>
        <SelectionButton
          htmlFor={DeviceType.Transmitter}
          value={DeviceType.Transmitter}
          name="device_type"
          onChange={onDeviceTypeChange}
          checked={activity.device_type === DeviceType.Transmitter}
        >
          {polyglot.t(`${polyglotPrefix}transmitter`)}
        </SelectionButton>
        <SelectionButton
          htmlFor={DeviceType.InsulinPen}
          value={DeviceType.InsulinPen}
          name="device_type"
          onChange={onDeviceTypeChange}
          checked={activity.device_type === DeviceType.InsulinPen}
        >
          {polyglot.t(`${polyglotPrefix}insulin_pen`)}
        </SelectionButton>
        <SelectionButton
          htmlFor={DeviceType.InfusionSet}
          value={DeviceType.InfusionSet}
          name="device_type"
          onChange={onDeviceTypeChange}
          checked={activity.device_type === DeviceType.InfusionSet}
        >
          {polyglot.t(`${polyglotPrefix}infusion_set`)}
        </SelectionButton>
        <SelectionButton
          htmlFor={DeviceType.PumpCartridge}
          value={DeviceType.PumpCartridge}
          name="device_type"
          onChange={onDeviceTypeChange}
          checked={activity.device_type === DeviceType.PumpCartridge}
        >
          {polyglot.t(`${polyglotPrefix}pump_cartridge`)}
        </SelectionButton>
      </SelectionButtons>

    </FieldSet>
  )
}
