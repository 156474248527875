import {
  StarOutlineIcon,
  SearchIcon,
} from "@tandem-mobile/react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import { ActivityIcon } from "src/components"
import type { FeedButton } from "src/types"

export interface Props extends ComponentPropsWithoutRef<"svg"> {
  activityType: FeedButton;
}

export const ActivityButtonIcon = forwardRef<SVGElement, Props>(
  function ActivityButtonIcon(props: Props, ref: Ref<SVGElement>) {
    const {
      activityType,
      ...svgProps
    } = props

    switch (activityType) {
      case "favorite":
        return (
          <StarOutlineIcon {...svgProps}
            ref={ref}
          />
        )
      case "search":
        return (
          <SearchIcon {...svgProps}
            ref={ref}
          />
        )
      default:
        return (
          <ActivityIcon
            {...svgProps}
            ref={ref}
            activityType={activityType}
          />
        )
    }
  },
)
