import { NoteOutlineIcon } from "@tandem-mobile/react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import type {
  ReadOnlySubEntry,
  ReadOnlyUserEvent,
  ActivityPair,
} from "src/types"
import {
  ActivityType,
  ExerciseIntensity,
} from "src/types"

export type Props = ComponentPropsWithoutRef<"svg"> & ActivityPair & {
  item: ReadOnlySubEntry | ReadOnlyUserEvent;
}

// of a message to show its note
export const ActivityNoteIcon = forwardRef<SVGElement, Props>(
  function ActivityNoteIcon(props: Props, ref: Ref<SVGElement>) {
    const {
      item,
      activity,
      activityType,
      ...svgProps
    } = props

    // this only shows for exercises at the moment
    if (activityType !== ActivityType.Exercise) {
      return null
    }

    const userEvent = item as ReadOnlyUserEvent
    const subEntry = item as ReadOnlySubEntry

    const hasDuration = subEntry.duration_in_seconds || (
      userEvent.ended_at !== userEvent.started_at
    )

    // this should only show when the note will not appear in the message
    // the note will appear in the message if the exercise has a duration or intensity
    if (
      (!hasDuration && activity.intensity === ExerciseIntensity.None) || !item.note
    ) {
      return null
    }

    return (
      <NoteOutlineIcon
        {...svgProps}
        ref={ref}
      />
    )
  },
)
