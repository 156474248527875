import {
  LargeMenuModalItem,
  LargeMenuModalItemButton,
  LargeMenuModalItemIcon,
  LargeMenuModalItemLabel,
  LargeMenuModalItemText,
  LargeMenuModalItemSubtext,
  LargeMenuModalItemIconButtons,
  IconButton,
  EditIcon,
  TrashCanOutlineIcon,
  useModalContext,
} from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { MouseEventHandler } from "react"
import { useCallback } from "react"

import { useDeleteFavoriteDialogContext } from "./DeleteFavoriteDialog"
import type { EditableEntryTemplate } from "./EditableEntryTemplate"
import { useEditFavoriteDialogContext } from "./EditFavoriteDialog"
import styles from "./FavoritesModal.module.scss"
import { useEntryModalPublicContext } from "src/components/EntryModal"
import {
  FavoriteMessage,
  FavoriteIcon,
} from "src/components/Favorite"

interface Props {
  entryTemplate: EditableEntryTemplate;
  creationTime?: string;
}

export function FavoriteMenuItem(props: Props) {
  const {
    entryTemplate,
    creationTime,
  } = props
  const { setOpen } = useModalContext()
  const { openEntryModal } = useEntryModalPublicContext()
  const { openDeleteFavoriteDialog } = useDeleteFavoriteDialogContext()
  const { openEditFavoriteDialog } = useEditFavoriteDialogContext()

  const onInstantiateClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      openEntryModal({
        userEntry: {
          entry_template_id: entryTemplate.id,
          started_at: creationTime ?? dayjs().toISOString(),
          subentries: entryTemplate.subentries,
        },
      })
      setOpen(false)
    },
    [
      creationTime,
      setOpen,
      entryTemplate,
      openEntryModal,
    ],
  )

  const onEditClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      openEditFavoriteDialog(entryTemplate)
    },
    [
      entryTemplate,
      openEditFavoriteDialog,
    ],
  )

  const onDeleteClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      openDeleteFavoriteDialog(entryTemplate)
    },
    [
      entryTemplate,
      openDeleteFavoriteDialog,
    ],
  )

  return (
    <LargeMenuModalItem className={styles.item}>
      <LargeMenuModalItemButton
        className={styles.itemButton}
        onClick={onInstantiateClick}
      >
        <LargeMenuModalItemIcon>
          <FavoriteIcon />
        </LargeMenuModalItemIcon>
        <LargeMenuModalItemText>
          <LargeMenuModalItemLabel>
            {entryTemplate.title}
          </LargeMenuModalItemLabel>
          <LargeMenuModalItemSubtext asChild>
            <FavoriteMessage subEntries={entryTemplate.subentries} />
          </LargeMenuModalItemSubtext>
        </LargeMenuModalItemText>
      </LargeMenuModalItemButton>
      <LargeMenuModalItemIconButtons>
        <IconButton
          onClick={onEditClick}
          stylePreset="withoutBackground"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={onDeleteClick}
          stylePreset="withoutBackground"
        >
          <TrashCanOutlineIcon />
        </IconButton>
      </LargeMenuModalItemIconButtons>
    </LargeMenuModalItem>
  )
}
