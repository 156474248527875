import { CameraOutlineIcon } from "@tandem-mobile/react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import type { ReadOnlySubEntry } from "src/types"

// note: this is only for subentries, not events
export type Props = ComponentPropsWithoutRef<"svg"> & {
  item: ReadOnlySubEntry;
}

// this shows a note icon when an exercise item has too long
// of a message to show its note
export const ActivityImageIcon = forwardRef<SVGElement, Props>(
  function ActivityImageIcon(props: Props, ref: Ref<SVGElement>) {
    const {
      item,
      ...svgProps
    } = props

    const imageUrl = item.image_url

    if (!imageUrl) {
      return null
    }

    return (
      <CameraOutlineIcon
        {...svgProps}
        ref={ref}
      />
    )
  },
)
