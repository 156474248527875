import {
  DropletOutlineIcon,
  GlucoseMonitorIcon,
} from "@tandem-mobile/react-components"
import { forwardRef } from "react"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"

import styles from "./FeedItemHeader.module.scss"
import { useEndedAtGlucoseTime } from "./useEndedAtGlucoseTime"
import { useReadableNearestGlucoseReading } from "src/hooks"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  userItem: ReadOnlyUserEvent | ReadOnlyUserEntry;
}

function EndedAtGlucoseValue(props: { endTime: string }) {
  const { endTime } = props
  const glucoseEnd = useReadableNearestGlucoseReading(endTime)

  if (!glucoseEnd) {
    return null
  }

  const {
    readableValue,
    isUserEntry,
  } = glucoseEnd

  return (
    <>
      <pre className={styles.pre}>
        {" ➞ "}
      </pre>
      {isUserEntry
        ? <GlucoseMonitorIcon className={styles.summaryIcon} />
        : <DropletOutlineIcon className={styles.summaryIcon} />
      }
      <pre className={styles.pre}>
        {` ${readableValue}`}
      </pre>
    </>
  )
}

export const FeedItemHeaderGlucoseSummary = forwardRef<HTMLDivElement, Props>(
  function FeedItemHeaderGlucoseSummary(props: Props, propsRef: Ref<HTMLDivElement>) {
    const { userItem } = props
    const startTime = userItem.started_at
    const endTime = useEndedAtGlucoseTime(userItem)
    const glucoseStart = useReadableNearestGlucoseReading(startTime)

    if (!glucoseStart) {
      return null
    }

    const {
      readableValue,
      isUserEntry,
    } = glucoseStart

    return (
      <div
        ref={propsRef}
        className={props.className}
      >
        {isUserEntry
          ? <GlucoseMonitorIcon className={styles.summaryIcon} />
          : <DropletOutlineIcon className={styles.summaryIcon} />
        }
        <pre className={styles.pre}>
          {` ${readableValue}`}
        </pre>
        {endTime && (
          <EndedAtGlucoseValue endTime={endTime} />
        )}
      </div>
    )
  },
)
