import {
  IconButton,
  PlusIcon,
  Label,
  InfoButton,
  MenuButton,
} from "@tandem-mobile/react-components"
import { useCallback } from "react"
import { AccountInsulinComponent } from "./AccountInsulinComponent"
import styles from "./InsulinAndMedication.module.scss"
import { useAccountInsulinModalContext } from "src/components"
import { usePolyglot } from "src/contexts"
import { useAccountInsulins } from "src/hooks"

const polyglotPrefix = "pages.settings.insulin_and_medication.insulin."

export function AccountInsulins() {
  const polyglot = usePolyglot()
  const { openAccountInsulinModal } = useAccountInsulinModalContext()

  const {
    result: accountInsulins,
    isFetching,
  } = useAccountInsulins()

  const onClick = useCallback(
    () => {
      openAccountInsulinModal()
    },
    [openAccountInsulinModal],
  )

  return (
    <div>
      <div className={styles.labelRow}>
        <Label>
          {polyglot.t(`${polyglotPrefix}label`)}
          <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
            {polyglot.t(`${polyglotPrefix}description`)}
          </InfoButton>
        </Label>
        <IconButton
          onClick={onClick}
          stylePreset="withoutBackground"
        >
          <PlusIcon />
        </IconButton>
      </div>
      {isFetching ? (
        <MenuButton
          disabled
          className={styles.loadingButton}
          onClick={onClick}
        >
          {polyglot.t(`${polyglotPrefix}empty_cta`)}
        </MenuButton>
      ) : accountInsulins.length == 0 ? (
        <MenuButton
          className={styles.emptyButton}
          onClick={onClick}
        >
          {polyglot.t(`${polyglotPrefix}empty_cta`)}
        </MenuButton>
      ) : (
        <ul className={styles.list}>
          {
            accountInsulins.map(
              (accountInsulin) => (
                <AccountInsulinComponent
                  key={accountInsulin.id}
                  accountInsulin={accountInsulin}
                />
              ),
            )
          }
        </ul>
      )}
    </div>
  )
}
