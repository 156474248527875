import { Dialog } from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { ReactNode } from "react"
import {
  useCallback,
  useState,
  useMemo,
} from "react"
import { DataSourceAlreadyExistsDialogContent } from "./DataSourceAlreadyExistsDialogContent"
import { DataSourceAlreadyExistsDialogContext } from "./DataSourceAlreadyExistsDialogContext"
import { NoNewReadingsDialogContent } from "./NoNewReadingsDialogContent"
import { NoNewReadingsDialogContext } from "./NoNewReadingsDialogContext"
import type { Source } from "src/types"

interface Props {
  children: ReactNode;
  source?: Source;
}

/*
 * this component handles nesting the warning dialogs so that the dismiss
 * events bubble properly and they are dismissed one at a time
 */
export function WarningDialogs(props: Props) {
  const [
    isAlreadyExistsOpen,
    setIsAlreadyExistsOpen,
  ] = useState<boolean>(
    // note leaving this URL param in because the mobile clients might use it
    (new URLSearchParams(window.location.search)).get("already_exists") === "true",
  )

  const [
    isNoNewReadingsOpen,
    setIsNoNewReadingsOpen,
  ] = useState<boolean>(
    dayjs()
      .subtract(
        10,
        "minutes",
      )
      .isAfter(
        // note leaving this URL param in because the mobile clients might use it
        (new URLSearchParams(window.location.search)).get("last_point_at") ?? dayjs(),
      ),
  )

  const openDataSourceAlreadyExistsDialog = useCallback(
    () => {
      setIsAlreadyExistsOpen(true)
    },
    [setIsAlreadyExistsOpen],
  )

  const dataSourceAlreadyExistsContextValue = useMemo(
    () => ({
      openDataSourceAlreadyExistsDialog,
    }),
    [openDataSourceAlreadyExistsDialog],
  )

  const openNoNewReadingsDialog = useCallback(
    () => {
      setIsNoNewReadingsOpen(true)
    },
    [setIsNoNewReadingsOpen],
  )

  const noNewReadingsContextValue = useMemo(
    () => ({
      openNoNewReadingsDialog,
    }),
    [openNoNewReadingsDialog],
  )

  return (
    <>
      <NoNewReadingsDialogContext.Provider value={noNewReadingsContextValue}>
        <DataSourceAlreadyExistsDialogContext.Provider value={dataSourceAlreadyExistsContextValue}>
          {props.children}
        </DataSourceAlreadyExistsDialogContext.Provider>
      </NoNewReadingsDialogContext.Provider>
      <Dialog
        open={isNoNewReadingsOpen}
        onOpenChange={setIsNoNewReadingsOpen}
      >
        <NoNewReadingsDialogContent source={props.source}>
          <Dialog
            open={isAlreadyExistsOpen}
            onOpenChange={setIsAlreadyExistsOpen}
          >
            <DataSourceAlreadyExistsDialogContent />
          </Dialog>
        </NoNewReadingsDialogContent>
      </Dialog>
      {!isNoNewReadingsOpen && (
        <Dialog
          open={isAlreadyExistsOpen}
          onOpenChange={setIsAlreadyExistsOpen}
        >
          <DataSourceAlreadyExistsDialogContent />
        </Dialog>
      )}
    </>
  )
}
