import { Label } from "@tandem-mobile/react-components"
import styles from "./Account.module.scss"
import { usePolyglot } from "src/contexts"
import { useAccountProperty } from "src/hooks"

const emailId = "email-id"
const polyglotPrefix = "pages.settings.account.email."

export function Email() {
  const polyglot = usePolyglot()
  const email = useAccountProperty(
    "email",
    "",
  )

  return (
    <div className={styles.inputContainer}>
      <Label
        htmlFor={emailId}
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
      </Label>
      <input
        id={emailId}
        className={styles.emailInput}
        value={email}
        readOnly
      />
    </div>
  )
}
