import {
  Dialog,
  useToasterContext,
} from "@tandem-mobile/react-components"
import type { ReactNode } from "react"
import {
  useState,
  useCallback,
  useMemo,
} from "react"

import { DataConsentDialogContext } from "./DataConsentDialogContext"
import { DataConsentForm } from "./DataConsentForm"
import { useNightscoutDialogContext } from "./NightscoutDialogContext"
import { useGetDexcomRedirectURL } from "src/hooks"
import { AddSourceErrorToast } from "src/toasts"
import { SourceType } from "src/types"

interface Props {
  onDexcomAdd?: () => void;
  children: ReactNode;
}

export function DataConsentDialog(props: Props) {
  const {
    children,
    onDexcomAdd,
  } = props

  const { openNightscoutDialog } = useNightscoutDialogContext()
  const getDexcomRedirectURL = useGetDexcomRedirectURL()
  const { showToast } = useToasterContext()

  const [
    sourceType,
    setSourceType,
  ] = useState<SourceType.Nightscout | SourceType.DexcomRest | null>(null)

  const openConsentDialog = useCallback(
    (source: SourceType.Nightscout | SourceType.DexcomRest) => {
      setSourceType(source)
    },
    [setSourceType],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        setSourceType(null)
      }
    },
    [setSourceType],
  )

  const contextValue = useMemo(
    () => ({
      openConsentDialog,
    }),
    [openConsentDialog],
  )

  const onConfirm = useCallback(
    () => {
      if (sourceType === null) {
        return
      }

      if (sourceType === SourceType.Nightscout) {
        openNightscoutDialog()
      } else {
        void (async () => {
          try {
            const response = await getDexcomRedirectURL()
            onDexcomAdd?.()
            location.replace(response.data.redirect_url)
          } catch (e) {
            showToast(
              "add-dexcom-source-error-toast",
              AddSourceErrorToast,
            )
          }
        })()

      }

      setSourceType(null)
    },
    [
      showToast,
      getDexcomRedirectURL,
      sourceType,
      openNightscoutDialog,
      setSourceType,
      onDexcomAdd,
    ],
  )

  return (
    <Dialog
      open={!!sourceType}
      onOpenChange={onOpenChange}
    >
      <DataConsentDialogContext.Provider value={contextValue}>
        {children}
      </DataConsentDialogContext.Provider>
      <DataConsentForm onConfirm={onConfirm} />
    </Dialog>
  )
}
