import { LockIcon } from "@tandem-mobile/react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

// note: we might need to update this to only show for some events
export type Props = ComponentPropsWithoutRef<"svg">

// this shows a note icon when an exercise item has too long
// of a message to show its note
export const ActivityLockIcon = forwardRef<SVGElement, Props>(
  function ActivityLockIcon(props: Props, ref: Ref<SVGElement>) {
    return (
      <LockIcon
        {...props}
        ref={ref}
      />
    )
  },
)
