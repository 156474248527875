import {
  ModalContentForm,
  useToasterContext,
} from "@tandem-mobile/react-components"
import type { FormEventHandler } from "react"
import { useCallback } from "react"

import { useEntryModalPrivateContext } from "./EntryModalPrivateContext"
import { useEntryModalPublicContext } from "./EntryModalPublicContext"
import { EntrySummary } from "./EntrySummary"
import { SubEntryForm } from "./SubEntryForm"
import { SubEntryTypeSelector } from "./SubEntryTypeSelector"
import { SaveEntryErrorToast } from "src/toasts"
import type {
  SubEntryType,
  ReadOnlyUserEntry,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"

interface Props {
  currentUserEntry: EditableUserEntry;
  currentSubEntry: EditableSubEntry | undefined;
  editSubEntry: (e: EditableSubEntry | undefined) => void;
  editUserEntry: (e: EditableUserEntry) => void;
}

export function EntryModalContent(props: Props) {
  const {
    currentSubEntry,
    currentUserEntry,
    editSubEntry,
    editUserEntry,
  } = props

  const {
    saveEntry,
    formId,
  } = useEntryModalPrivateContext()

  const { closeEntryModal } = useEntryModalPublicContext()
  const { showToast } = useToasterContext()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          await saveEntry()
          closeEntryModal()
          // TODO show success toast ?
        } catch (e) {
          showToast(
            "save-entry-error-toast",
            SaveEntryErrorToast,
          )
        }
      })()
    },
    [
      showToast,
      saveEntry,
      closeEntryModal,
    ],
  )

  return (
    <ModalContentForm
      onSubmit={onSubmit}
      id={formId}
    >
      {
        !currentSubEntry ? (
          <EntrySummary
            currentUserEntry={currentUserEntry as ReadOnlyUserEntry}
            setCurrentUserEntry={editUserEntry}
          />
        ) : currentSubEntry.subentry_type === undefined ? (
          <SubEntryTypeSelector
            createSubEntry={editSubEntry}
            currentUserEntry={currentUserEntry}
            currentSubEntry={currentSubEntry as EditableSubEntry<undefined>}
          />
        ) : (
          <SubEntryForm
            currentSubEntry={currentSubEntry as EditableSubEntry<SubEntryType>}
            setCurrentSubEntry={editSubEntry}
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={editUserEntry}
          />
        )
      }
    </ModalContentForm>
  )
}
