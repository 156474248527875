import {
  StarOutlineIcon,
  StarFillIcon,
} from "@tandem-mobile/react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

export interface Props extends ComponentPropsWithoutRef<"svg"> {
  fillIcon?: boolean;
}

export const FavoriteIcon = forwardRef<SVGElement, Props>(
  function FavoriteIcon(props: Props, ref: Ref<SVGElement>) {
    const {
      fillIcon = false,
      ...svgProps
    } = props

    if (fillIcon) {
      return (
        <StarFillIcon
          {...svgProps}
          ref={ref}
        />
      )
    }

    return (
      <StarOutlineIcon
        {...svgProps}
        ref={ref}
      />
    )
  },
)
