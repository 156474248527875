import {
  ActionButton,
  Dialog,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  DialogContent,
  DialogHeading,
  useToasterContext,
} from "@tandem-mobile/react-components"
import type { ReactNode } from "react"
import {
  useCallback,
  useState,
  useMemo,
} from "react"
import type { EditableEntryTemplate } from "../EditableEntryTemplate"
import styles from "./DeleteFavoriteDialog.module.scss"
import { DeleteFavoriteDialogContext } from "./DeleteFavoriteDialogContext"
import { usePolyglot } from "src/contexts"
import { useDeleteEntryTemplate } from "src/hooks"
import {
  DeleteFavoriteErrorToast,
  DeleteFavoriteSuccessToast,
} from "src/toasts"
import { injectProps } from "src/utils"

const polyglotPrefix = "components.favorites_modal.delete_favorite_dialog."

interface Props {
  children: ReactNode;
}

export function DeleteFavoriteDialog(props: Props) {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const deleteEntryTemplate = useDeleteEntryTemplate()

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const [
    entryTemplate,
    setEntryTemplate,
  ] = useState<EditableEntryTemplate | undefined>(undefined)

  const openDialog = useCallback(
    (newEntryTemplate: EditableEntryTemplate) => {
      setEntryTemplate(newEntryTemplate)
      setIsOpen(true)
    },
    [
      setIsOpen,
      setEntryTemplate,
    ],
  )

  const contextValue = useMemo(
    () => ({
      openDeleteFavoriteDialog: openDialog,
    }),
    [openDialog],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        return
      }

      setIsOpen(false)
    },
    [setIsOpen],
  )

  const onDeleteClick = useCallback(
    () => {
      if (!entryTemplate) {
        return
      }

      void (async () => {
        try {
          await deleteEntryTemplate(entryTemplate.id)

          const SuccessToastComponent = injectProps(
            DeleteFavoriteSuccessToast,
            { title: entryTemplate.title },
          )

          showToast(
            "delete-favorite-success-toast",
            SuccessToastComponent,
          )

          setIsOpen(false)
        } catch (e) {
          const ErrorToastComponent = injectProps(
            DeleteFavoriteErrorToast,
            { title: entryTemplate.title },
          )

          showToast(
            "delete-favorite-error-toast",
            ErrorToastComponent,
          )
        }
      })()
    },
    [
      deleteEntryTemplate,
      entryTemplate,
      setIsOpen,
      showToast,
    ],
  )

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <DeleteFavoriteDialogContext.Provider value={contextValue}>
        {props.children}
      </DeleteFavoriteDialogContext.Provider>
      {entryTemplate && (
        <DialogContent>
          <DialogHeading>
            {polyglot.t(
              `${polyglotPrefix}header`,
              { title: entryTemplate.title },
            )}
            <DialogCloseX />
          </DialogHeading>
          <DialogButtons>
            <ActionButton
              className={styles.dialogMultiButton}
              stylePreset="destructive"
              onClick={onDeleteClick}
            >
              {polyglot.t(`${polyglotPrefix}confirm_cta`)}
            </ActionButton>
            <DialogButton>
              {polyglot.t(`${polyglotPrefix}cancel_cta`)}
            </DialogButton>
          </DialogButtons>
        </DialogContent>
      )}
    </Dialog>
  )
}
