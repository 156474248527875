import { Toast } from "@tandem-mobile/react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
}

export function FavoriteEntryGlucoseErrorToast(props: Props) {
  const { id } = props

  const polyglot = usePolyglot()

  return (
    <Toast
      stylePreset="error"
      body={polyglot.t("toasts.favorite_entry_glucose_error.body")}
      id={id}
    />
  )
}
