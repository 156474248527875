import { Modal } from "@tandem-mobile/react-components"
import dayjs from "dayjs"
import type { ReactNode } from "react"
import {
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react"

import { EntryModalContent } from "./EntryModalContent"
import { EntryModalPrivateContextProvider } from "./EntryModalPrivateContextProvider"
import { EntryModalPublicContext } from "./EntryModalPublicContext"
import { newSubEntryTemplate } from "./newSubEntryTemplate"
import { DiscardChangesDialog } from "src/components/DiscardChangesDialog"
import {
  useUserEntriesCollection,
  useEditableType,
  useLoadEntryTemplatesCollection,
} from "src/hooks"
import type {
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"

type Props = {
  children?: ReactNode;
}

/*
 * TODO it would be easy to make a variation of the entry modal that just shows a user event
 *
 * shows the user a modal for editing entries
 */
export function EntryModal(props: Props) {
  // open states for entry modal and unsaved changes dialog
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)

  useLoadEntryTemplatesCollection()
  const userEntriesCollection = useUserEntriesCollection()

  // value to use as an empty user entry when creating a new user entry
  const emptyUserEntryRef = useRef<EditableUserEntry>({
    started_at: "",
    subentries: [],
  })

  // current editor state of user entry
  const {
    original: originalUserEntry,
    current: currentUserEntry,
    hasChanged: userEntryHasChanged,
    save: resetUserEntry,
    update: editUserEntry,
  } = useEditableType<EditableUserEntry>(emptyUserEntryRef.current)

  // this updates the original user entry when it is changed in the database
  // this should only happen when we save changes, delete, or create a favorite
  useEffect(
    () => {
      if (!userEntriesCollection || !originalUserEntry.id) {
        return
      }

      const query = userEntriesCollection.findOne({
        selector: {
          id: {
            $eq: originalUserEntry.id,
          },
        },
      })

      const subscription = query.$.subscribe(
        (results) => {
          if (!results) {
            return
          }

          const newUserEntry = results.toJSON()

          if (newUserEntry.deleted_at) {
            setIsOpen(false)
          } else {
            resetUserEntry(newUserEntry)
          }
        },
      )

      return () => {
        subscription.unsubscribe()
      }
    },
    [
      setIsOpen,
      resetUserEntry,
      originalUserEntry.id,
      userEntriesCollection,
    ],
  )

  // current editor state of sub entry
  const {
    original: originalSubEntry,
    current: currentSubEntry,
    hasChanged: subEntryHasChanged,
    save: resetSubEntry,
    update: editSubEntry,
  } = useEditableType<EditableSubEntry | undefined>(undefined)

  // opens the entry editor modal
  const openEntryModal = useCallback(
    (args?: {
      userEntry?: EditableUserEntry;
      subEntry?: EditableSubEntry;
    }) => {
      if (args) {
        const {
          userEntry,
          subEntry,
        } = args

        // update the currentSubEntry
        if (subEntry) {
          // open to this sub entry
          resetSubEntry(subEntry)
        } else if (!userEntry || !userEntry.subentries.length) {
          // open to creating a new sub entry
          resetSubEntry(newSubEntryTemplate)
        } else {
          // open to the user entry
          resetSubEntry(undefined)
        }

        // update the currentUserEntry
        if (userEntry) {
          resetUserEntry(userEntry)
        } else {
          emptyUserEntryRef.current = {
            started_at: dayjs().toISOString(),
            subentries: [],
          }

          resetUserEntry(emptyUserEntryRef.current)
        }
      }

      setIsOpen(true)
    },
    [
      resetSubEntry,
      resetUserEntry,
      emptyUserEntryRef,
      setIsOpen,
    ],
  )

  // a function that closes the modal and discards any changes
  const closeEntryModal = useCallback(
    () => {
      setIsOpen(false)
    },
    [setIsOpen],
  )

  // this value is true when the user has unsaved changes
  const hasUnsavedChanges = useMemo(
    () => !currentUserEntry.id || subEntryHasChanged || userEntryHasChanged,
    [
      subEntryHasChanged,
      userEntryHasChanged,
      currentUserEntry,
    ],
  )

  // clear entry template id field if the user makes changes
  useEffect(
    () => {
      if (currentUserEntry.entry_template_id && subEntryHasChanged) {
        editUserEntry({
          ...currentUserEntry,
          entry_template_id: undefined,
        })
      }
    },
    [
      editUserEntry,
      currentUserEntry,
      subEntryHasChanged,
    ],
  )

  const publicContextValue = useMemo(
    () => ({
      closeEntryModal,
      hasUnsavedChanges,
      openEntryModal,
    }),
    [
      closeEntryModal,
      hasUnsavedChanges,
      openEntryModal,
    ],
  )

  const privateContextProps = {
    subEntryHasChanged,
    userEntryHasChanged,
    currentSubEntry,
    currentUserEntry,
    editSubEntry,
    editUserEntry,
    resetSubEntry,
    resetUserEntry,
    originalUserEntry,
    originalSubEntry,
  }

  // show warning if there are unsaved changes when the modal is being closed
  const onOpenChange = useCallback(
    // the only way to open the entry modal is the openEntryModal function
    (newOpen: boolean) => {
      if (!newOpen) {
        if (hasUnsavedChanges) {
          setIsDiscardOpen(true)
        } else {
          closeEntryModal()
        }
      }
    },
    [
      openEntryModal,
      closeEntryModal,
      setIsDiscardOpen,
      hasUnsavedChanges,
    ],
  )

  return (
    <EntryModalPublicContext.Provider value={publicContextValue}>
      <EntryModalPrivateContextProvider {...privateContextProps}>
        <Modal
          open={isOpen}
          onOpenChange={onOpenChange}
        >
          {props.children}
          <EntryModalContent
            currentUserEntry={currentUserEntry}
            currentSubEntry={currentSubEntry}
            editUserEntry={editUserEntry}
            editSubEntry={editSubEntry}
          />
        </Modal>
        <DiscardChangesDialog
          open={isDiscardOpen}
          setOpen={setIsDiscardOpen}
          discardChanges={publicContextValue.closeEntryModal}
        />
      </EntryModalPrivateContextProvider>
    </EntryModalPublicContext.Provider>
  )
}
