import { Toast } from "@tandem-mobile/react-components"
import { usePolyglot } from "src/contexts"
import type { InsightKey } from "src/types"

interface Props {
  id: string;
  insightKey: InsightKey;
}

export function EditInsightErrorToast(props: Props) {
  const {
    id, insightKey,
  } = props

  const polyglot = usePolyglot()

  return (
    <Toast
      stylePreset="error"
      body={polyglot.t(
        "toasts.edit_insight_error.body",
        { name: polyglot.t(`insights.title.${insightKey}`) },
      )}
      id={id}
    />
  )
}
