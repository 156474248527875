import {
  ModalBody,
  ModalCloseX,
  ModalHeading,
} from "@tandem-mobile/react-components"
import { useEffect } from "react"

import { ContextMenu } from "../ContextMenu"
import { DateTimeInput } from "../DateTimeInput"
import { ImageThumbnail } from "../ImageThumbnail"
import { NoteInput } from "../NoteInput"
import { SubEntryFormButtons } from "../SubEntryFormButtons"
import { UploadImage } from "../UploadImage"

import { CarbsRangeInput } from "./CarbsRangeInput"
import { defaultFoodActivity } from "./defaultFoodActivity"
import { FoodCarbsWarning } from "./FoodCarbsWarning"
import { FoodTitleRow } from "./FoodTitleRow"
import { FoodTitleWarning } from "./FoodTitleWarning"
import { NutritionDisplay } from "./NutritionDisplay"
import { ActivityIcon } from "src/components/Activity"
import styles from "src/styles/entryModal.module.scss"
import type {
  FoodActivity,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  updateActivity: (a: FoodActivity) => void;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Food>>) => void;
  currentSubEntry: EditableSubEntry<ActivityType.Food>;
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

export function FoodForm(props: Props) {
  const {
    updateActivity,
    updateSubEntry,
    currentSubEntry,
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const {
    food: activity = defaultFoodActivity,
  } = currentSubEntry

  // initialize the activity to the default if it isn't defined
  useEffect(
    () => {
      if (!currentSubEntry.food) {
        updateActivity(defaultFoodActivity)
      }
    },
    [
      currentSubEntry.food,
      updateActivity,
    ],
  )

  const SaveWarning = activity.carbs === defaultFoodActivity.carbs
    ? FoodCarbsWarning
    : activity.title === defaultFoodActivity.title
      ? FoodTitleWarning
      : undefined

  return (
    <>
      <ModalHeading>
        <div className={styles.headingContent}>
          <ActivityIcon
            activityType={ActivityType.Food}
            className={styles.activityIcon}
          />
        </div>
        <ContextMenu
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
        <ModalCloseX />
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        <div>
          <FoodTitleRow
            activity={activity}
            updateActivity={updateActivity}
            updateSubEntry={updateSubEntry}
          />
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
        </div>
        <CarbsRangeInput
          activity={activity}
          updateActivity={updateActivity}
        />
        <div className={styles.row}>
          <NutritionDisplay
            activity={activity}
            updateActivity={updateActivity}
          />
          <ImageThumbnail
            currentSubEntry={currentSubEntry}
            updateSubEntry={updateSubEntry}
          />
        </div>
        <div className={styles.row}>
          <NoteInput
            currentSubEntry={currentSubEntry}
            updateSubEntry={updateSubEntry}
          />
          <UploadImage updateSubEntry={updateSubEntry} />
        </div>
        <SubEntryFormButtons
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          SaveWarning={SaveWarning}
        />
      </ModalBody>
    </>
  )
}
