import { OverlayListItem } from "@tandem-mobile/react-components"
import styles from "./Activity.module.scss"
import { ActivityButton } from "./ActivityButton"
import type { FeedButton } from "src/types"

interface Props {
  id: string;
}

// TODO convert to menu button ?
export function OverlayActivityButton(props: Props) {
  const { id } = props

  return (
    <OverlayListItem
      className={styles.activityButton}
      id={props.id}
    >
      <ActivityButton activityType={id as FeedButton} />
    </OverlayListItem>
  )
}
