import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
  InfoButton,
  Label,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import {
  useEffect,
  useCallback,
  useMemo,
} from "react"

import type { DeepReadonlyObject } from "rxdb"

import styles from "./AccountInsulinModal.module.scss"
import type { EditableAccountInsulin } from "./EditableAccountInsulin"
import { emptyAccountInsulin } from "./EditableAccountInsulin"
import { usePolyglot } from "src/contexts"
import type { Insulin } from "src/types"

interface Props {
  currentInsulin?: DeepReadonlyObject<Insulin>;
  currentAccountInsulin: EditableAccountInsulin;
  editAccountInsulin: (e: EditableAccountInsulin) => void;
}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const id = "insulin_form_select"
const polyglotPrefix = "components.account_insulin_modal.form."

export function InsulinFormSelect(props: Props) {
  const {
    currentInsulin,
    currentAccountInsulin,
    editAccountInsulin,
  } = props

  const polyglot = usePolyglot()

  // if there is only 1 possible form, automatically select it
  useEffect(
    () => {
      if (
        currentInsulin?.id === currentAccountInsulin.insulin_id &&
        currentInsulin?.forms?.length === 1 &&
        currentAccountInsulin.form !== currentInsulin.forms[0]
      ) {
        editAccountInsulin({
          ...currentAccountInsulin,
          form: currentInsulin.forms[0],
        })
      }

    },
    [
      editAccountInsulin,
      currentAccountInsulin,
      currentInsulin?.forms,
    ],
  )

  const options: Array<Option> = useMemo(
    () => {
      const optionsArray: Array<Option> = [
        // the placeholder option
        {
          value: emptyAccountInsulin.form,
          children: polyglot.t(`${polyglotPrefix}placeholder`),
          disabled: true,
        },
      ]

      currentInsulin?.forms?.forEach((form) => {
        optionsArray.push({
          value: form,
          // TODO we are not localizing form
          children: form,
        })
      })

      return optionsArray
    },
    [
      polyglot,
      currentInsulin?.forms,
    ],
  )

  // update the currentAccountInsulin's form
  const onFormChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const form = e.target.value

      // update insulin form
      editAccountInsulin({
        ...currentAccountInsulin,
        form,
      })
    },
    [
      currentAccountInsulin,
      editAccountInsulin,
    ],
  )

  return (
    <div>
      <Label htmlFor={id}
        sizePreset="small"
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Dropdown>
        <DropdownSelect
          id={id}
          disabled={!currentInsulin}
          value={currentAccountInsulin.form ?? ""}
          onChange={onFormChange}
          required
        >
          {options.map(
            (selectOption) => (
              <DropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </DropdownSelect>
        <DropdownCurrentSelection />
        <DropdownCaret />
      </Dropdown>
    </div>
  )
}
