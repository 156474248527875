import {
  ActionSubmit,
  Form,
  LargeInput,
  useToasterContext,
  ValidationContainer,
  ValidationMessage,
} from "@tandem-mobile/react-components"
import escapeStringRegexp from "escape-string-regexp"
import type {
  FormEventHandler,
  ChangeEventHandler,
} from "react"
import {
  useCallback,
  useState,
} from "react"
import type { PrivacyFormData } from "./PrivacyFormData"
import styles from "./SignUp.module.scss"
import { PublicPageHeader } from "src/components"
import { usePolyglot } from "src/contexts"
import { useCreateAccount } from "src/hooks"
import { SignUpErrorToast } from "src/toasts"

const emailId = "user-email-input"
const passwordId = "user-password-input"
const passwordConfirmationId = "user-password-confirmation-input"
const polyglotPrefix = "pages.sign_up.new_user_account."

interface Props {
  privacyFormData: PrivacyFormData;
  goBack: () => void;
  onAccountCreate: (email: string, password: string) => Promise<void>;
}

export function NewUserAccountForm(props: Props) {
  const {
    onAccountCreate,
    goBack,
    privacyFormData,
  } = props

  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const createAccount = useCreateAccount()

  const [
    formState,
    setFormState,
  ] = useState<{
    email: string;
    password: string;
    passwordConfirmation: string;
  }>({
    email: "",
    password: "",
    passwordConfirmation: "",
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          const response = await createAccount({
            email: formState.email,
            password: formState.password,
            country: privacyFormData.country.toUpperCase(),
            units: privacyFormData.units,
          })

          // the v2 endpoint returns status 200 even if the request fails
          // so we must check the "status" field of the response
          if (response.data.status === "fail") {
            throw new Error("request failed")
          }

          await onAccountCreate(
            formState.email,
            formState.password,
          )
        } catch (e) {
          showToast(
            "sign-up-error-toast",
            SignUpErrorToast,
          )
        }
      })()
    },
    [
      formState,
      privacyFormData,
      createAccount,
      showToast,
      onAccountCreate,
    ],
  )

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        email: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        password: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onPasswordConfirmationChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        passwordConfirmation: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  // this pattern will match an empty value or a value that exactly matches the password field
  const passwordConfirmationPattern = `^(${escapeStringRegexp(formState.password)})?$`

  return (
    <>
      <PublicPageHeader onBackClick={goBack}>
        {polyglot.t(`${polyglotPrefix}title`)}
      </PublicPageHeader>
      <Form
        className={styles.form}
        onSubmit={onSubmit}
      >
        <ValidationContainer>
          <LargeInput
            id={emailId}
            placeholder={polyglot.t(`${polyglotPrefix}email.placeholder`)}
            className={styles.input}
            type="email"
            value={formState.email}
            onChange={onEmailChange}
            autoComplete="email"
            required
          />
          <ValidationMessage
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              typeMismatch: polyglot.t("validation_message.email_type_mismatch"),
            }}
          />
        </ValidationContainer>
        <ValidationContainer>
          <LargeInput
            id={passwordId}
            placeholder={polyglot.t(`${polyglotPrefix}password.placeholder`)}
            className={styles.input}
            type="password"
            value={formState.password}
            onChange={onPasswordChange}
            autoComplete="new-password"
            minLength={6}
            required
          />
          <ValidationMessage
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              tooShort: polyglot.t("validation_message.password_too_short"),
            }}
          />
        </ValidationContainer>
        <ValidationContainer>
          <LargeInput
            id={passwordConfirmationId}
            placeholder={polyglot.t(`${polyglotPrefix}password_confirmation.placeholder`)}
            className={styles.input}
            type="password"
            value={formState.passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            pattern={passwordConfirmationPattern}
            autoComplete="new-password"
            required
          />
          <ValidationMessage
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              patternMismatch: polyglot.t("validation_message.password_mismatch"),
            }}
          />
        </ValidationContainer>
        <ActionSubmit
          value={polyglot.t(`${polyglotPrefix}submit_cta`)}
          stylePreset="primary"
          className={styles.submit}
        />
      </Form>
    </>
  )
}
