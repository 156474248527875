import { InsulinOutlineIcon } from "@tandem-mobile/react-components"
import {
  forwardRef,
  useMemo,
} from "react"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"

import styles from "./FeedItemHeader.module.scss"
import { usePolyglot } from "src/contexts"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"
import { ActivityType } from "src/types"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"div"> {
  userItem: ReadOnlyUserEvent | ReadOnlyUserEntry;
}

export const FeedItemHeaderInsulinSummary = forwardRef<HTMLDivElement, Props>(
  function FeedItemHeaderInsulinSummary(props: Props, propsRef: Ref<HTMLDivElement>) {
    const { userItem } = props
    const polyglot = usePolyglot()

    const totalInsulinUnits = useMemo(
      () => {
        const userEvent = userItem as ReadOnlyUserEvent
        if (userEvent.event_type) {
          if (userEvent.event_type === ActivityType.Insulin) {
            return userEvent.insulin.num_units || 0
          }

          return 0
        }

        const userEntry = userItem as ReadOnlyUserEntry
        return userEntry.subentries.reduce(
          (accumulator: number, subEntry: ReadOnlyUserEntry["subentries"][0]) => {
            if (subEntry.subentry_type === ActivityType.Insulin) {
              return accumulator + (subEntry.insulin.num_units || 0)
            }

            return accumulator
          },
          0,
        )
      },
      [userItem],
    )

    if (!totalInsulinUnits) {
      return null
    }

    return (
      <div
        ref={propsRef}
        className={props.className}
      >
        <InsulinOutlineIcon className={styles.summaryIcon} />
        <pre className={styles.pre}>
          {` ${polyglot.t(
            "unit.num_units_abbreviated",
            {
              units: round(
                totalInsulinUnits,
                3,
              ).toLocaleString(navigator.language),
            },
          )}`}
        </pre>
      </div>
    )
  },
)
