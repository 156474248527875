import {
  LargeMenuButton,
  LargeMenuButtonText,
  LargeMenuButtonLabelRow,
  LargeMenuButtonIcon,
  LargeMenuButtonLabel,
  LargeMenuButtonSubtext,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import { useInsightIcon } from "../useInsightIcon"
import { usePolyglot } from "src/contexts"
import type { InsightKey } from "src/types"

const polyglotPrefix = "pages.home.insights."

interface Props {
  onChange: ChangeEventHandler<HTMLInputElement>;
  insightKey: InsightKey;
  disabled: boolean;
  checked: boolean;
}

export function AddInsightButton(props: Props) {
  const {
    onChange,
    insightKey,
    disabled,
    checked,
  } = props

  const polyglot = usePolyglot()
  const InsightIcon = useInsightIcon(insightKey)

  return (
    <LargeMenuButton
      htmlFor={`add-insight-button=${insightKey}`}
      value={insightKey}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      aria-label={polyglot.t(`${polyglotPrefix}cta_label.${insightKey}`)}
    >
      <LargeMenuButtonText>
        <LargeMenuButtonLabelRow>
          {InsightIcon && (
            <LargeMenuButtonIcon>
              <InsightIcon />
            </LargeMenuButtonIcon>
          )}
          <LargeMenuButtonLabel >
            {polyglot.t(`${polyglotPrefix}cta_label.${insightKey}`)}
          </LargeMenuButtonLabel>
        </LargeMenuButtonLabelRow>
        <LargeMenuButtonSubtext>
          {polyglot.t(`${polyglotPrefix}cta_subtext.${insightKey}`)}
        </LargeMenuButtonSubtext>
      </LargeMenuButtonText>
    </LargeMenuButton>
  )
}
