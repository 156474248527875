import {
  FieldSet,
  Legend,
  SelectionButton,
  SelectionButtons,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import { useMemo } from "react"

import styles from "./NoteInput.module.scss"
import { usePreviousNotes } from "./usePreviousNotes"
import { usePolyglot } from "src/contexts"
import type { ActivityType } from "src/types"

interface Props {
  value: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  activityType: ActivityType;
  subentryId?: string;
}

export function PreviousNotes(props: Props) {
  const polyglot = usePolyglot()

  const skipSubentries = useMemo(
    () => props.subentryId ? [props.subentryId] : [],
    [props.subentryId],
  )
  const previousNotes = usePreviousNotes(
    props.activityType,
    skipSubentries,
  )

  if (!previousNotes.length) {
    return null
  }

  return (
    <FieldSet>
      <Legend
        className={styles.legend}
        sizePreset="small"
      >
        {polyglot.t("components.entry_modal.previous_notes.label")}
      </Legend>
      <SelectionButtons>
        {
          previousNotes.map(
            (previousNote: string) => (
              <SelectionButton
                key={previousNote}
                htmlFor={previousNote}
                value={previousNote}
                onChange={props.onChange}
                checked={props.value === previousNote}
              >
                {previousNote}
              </SelectionButton>
            ),
          )
        }
      </SelectionButtons>
    </FieldSet>
  )
}
