import {
  ActionSubmit,
  Dialog,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  DialogContentForm,
  DialogHeading,
  DialogBody,
  DialogTrigger,
  PopoverMenuItem,
  PopoverMenuItemSeparator,
  PopoverMenuItemIcon,
  StarOutlineIcon,
  Input,
  usePopoverContext,
  useToasterContext,
} from "@tandem-mobile/react-components"
import { AxiosError } from "axios"
import type {
  ChangeEventHandler,
  FormEventHandler,
} from "react"
import {
  useCallback,
  useState,
  useRef,
} from "react"
import { useEntryModalPrivateContext } from "../EntryModalPrivateContext"
import { useEntryModalPublicContext } from "../EntryModalPublicContext"
import styles from "./ContextMenu.module.scss"
import { DiscardChangesDialog } from "src/components/DiscardChangesDialog"
import { usePolyglot } from "src/contexts"
import { useCreateEntryTemplate } from "src/hooks"
import {
  FavoriteEntryErrorToast,
  FavoriteEntryGlucoseErrorToast,
  FavoriteEntrySuccessToast,
} from "src/toasts"
import { ActivityType } from "src/types"
import { injectProps } from "src/utils"

const polyglotPrefix = "components.entry_modal.entry_context_menu."

export function FavoriteEntry() {
  const polyglot = usePolyglot()
  const {
    originalUserEntry,
    originalSubEntry,
  } = useEntryModalPrivateContext()
  const { hasUnsavedChanges } = useEntryModalPublicContext()
  const { showToast } = useToasterContext()
  const { setOpen: setPopoverOpen } = usePopoverContext()
  const createEntryTemplate = useCreateEntryTemplate()

  const inputRef = useRef<HTMLInputElement>(null)

  const [
    discardChangesOpen,
    setDiscardChangesOpen,
  ] = useState<boolean>(false)

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const openDiscardChangesDialog = useCallback(
    () => {
      setDiscardChangesOpen(true)
    },
    [setDiscardChangesOpen],
  )

  const [
    favoriteName,
    setFavoriteName,
  ] = useState<string>("")

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFavoriteName(e.target.value)
    },
    [setFavoriteName],
  )

  const favoriteEntryCallback = useCallback(
    () => {
      void (async () => {
        try {
          if (!originalUserEntry.id) {
            throw new Error("cannot favorite unsaved user entry")
          }

          await createEntryTemplate({
            entry_id: originalUserEntry.id,
            title: favoriteName,
          })

          const SuccessToastComponent = injectProps(
            FavoriteEntrySuccessToast,
            { title: favoriteName },
          )

          showToast(
            "favorite-entry-success-toast",
            SuccessToastComponent,
          )
        } catch (e) {
          if (e instanceof AxiosError && e.response?.status === 400) {
            showToast(
              "favorite-entry-glucose-error-toast",
              FavoriteEntryGlucoseErrorToast,
            )
          } else {
            const ErrorToastComponent = injectProps(
              FavoriteEntryErrorToast,
              { title: favoriteName },
            )

            showToast(
              "favorite-entry-error-toast",
              ErrorToastComponent,
            )
          }
        }

        // TODO move this ?
        setPopoverOpen(false)
      })()
    },
    [
      showToast,
      favoriteName,
      createEntryTemplate,
      originalUserEntry,
      setPopoverOpen,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      if (hasUnsavedChanges) {
        openDiscardChangesDialog()
      } else {
        favoriteEntryCallback()
      }

      setIsOpen(false)
    },
    [
      hasUnsavedChanges,
      openDiscardChangesDialog,
      favoriteEntryCallback,
      setIsOpen,
    ],
  )

  // don't show this for entries that have already been favorited
  if (originalUserEntry.entry_template_id) {
    return null
  }

  // don't show this for glucose subentries
  if (originalSubEntry?.subentry_type === ActivityType.Glucose) {
    return null
  }

  return (

    <Dialog
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <DialogTrigger asChild>
        <PopoverMenuItem>
          <PopoverMenuItemIcon>
            <StarOutlineIcon />
          </PopoverMenuItemIcon>
          {polyglot.t(`${polyglotPrefix}favorite_cta`)}
        </PopoverMenuItem>
      </DialogTrigger>
      <PopoverMenuItemSeparator />
      <DialogContentForm onSubmit={onSubmit}>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}favorite_dialog_header`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogBody className={styles.dialogBody}>
          <Input
            ref={inputRef}
            type="text"
            placeholder={polyglot.t(`${polyglotPrefix}favorite_dialog_placeholder`)}
            value={favoriteName}
            onChange={onChange}
            required
          />
        </DialogBody>
        <DialogButtons>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}favorite_dialog_cancel`)}
          </DialogButton>
          <ActionSubmit
            value={polyglot.t(`${polyglotPrefix}favorite_dialog_confirm`)}
            className={styles.dialogMultiButton}
          />
        </DialogButtons>
      </DialogContentForm>
      <DiscardChangesDialog
        open={discardChangesOpen}
        setOpen={setDiscardChangesOpen}
        discardChanges={favoriteEntryCallback}
      />
    </Dialog>
  )
}
