import {
  LargeDropdown,
  LargeDropdownCaret,
  LargeDropdownCurrentSelection,
  LargeDropdownOption,
  LargeDropdownSelect,
  ValidationContainer,
  ValidationMessage,
} from "@tandem-mobile/react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
  useRef,
} from "react"

import { countries } from "./countries"
import type { PrivacyFormData } from "./PrivacyFormData"
import { usePolyglot } from "src/contexts"
import { GlucoseUnit } from "src/types"

interface Props {
  formState: PrivacyFormData;
  setFormState: (d: PrivacyFormData) => void;

}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const id = "privacy-residence-select"
const polyglotPrefix = "pages.sign_up.new_user_privacy.residence."

export function ResidenceSelect(props: Props) {
  const {
    formState,
    setFormState,
  } = props

  const polyglot = usePolyglot()
  const selectRef = useRef<HTMLSelectElement>(null)

  const options: Array<Option> = useMemo(
    () => {
      const optionsArray: Array<Option> = [
        // the placeholder option
        {
          value: "",
          children: polyglot.t(`${polyglotPrefix}placeholder`),
          disabled: true,
        },
      ]

      const countryOptions = Object.keys(countries)
        .filter(
          (countryCode: string): boolean => countries[countryCode].is_supported,
        )
        .map(
          (countryCode: string): Option => ({
            value: countryCode,
            children: polyglot.t(`country.${countryCode}`),
          }),
        )
        .sort(
          (optionA, optionB) => optionA.children.localeCompare(optionB.children),
        )

      optionsArray.push(...countryOptions)
      return optionsArray
    },
    [polyglot],
  )

  const onResidenceChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const countryCode = e.target.value
      const country = countries[countryCode]

      if (country?.is_supported) {
        selectRef.current?.setCustomValidity("")
      } else {
        selectRef.current?.setCustomValidity(polyglot.t(`${polyglotPrefix}unsupported_error`))
      }

      setFormState({
        ...formState,
        country: countryCode,
        units: country?.units ?? GlucoseUnit.MilligramsPerDeciliter,
      })
    },
    [
      polyglot,
      setFormState,
      formState,
      selectRef,
    ],
  )

  return (
    <ValidationContainer>
      <LargeDropdown>
        <LargeDropdownSelect
          id={id}
          value={formState.country}
          onChange={onResidenceChange}
          ref={selectRef}
          autoComplete="country-name"
          required
        >
          {options.map(
            (selectOption) => (
              <LargeDropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </LargeDropdownSelect>
        <LargeDropdownCurrentSelection />
        <LargeDropdownCaret />
      </LargeDropdown>
      <ValidationMessage
        invalidMessage={{
          valueMissing: polyglot.t("validation_message.value_missing"),
          customError: polyglot.t(`${polyglotPrefix}unsupported_error`),
        }}
      />
    </ValidationContainer>
  )
}
