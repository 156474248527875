import {
  GearOutlineIcon,
  IconButton,
} from "@tandem-mobile/react-components"
import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"
import styles from "./InsulinAndMedication.module.scss"
import { useAccountInsulinModalContext } from "src/components"
import type { AccountInsulin } from "src/types"

interface Props {
  accountInsulin: DeepReadonlyObject<AccountInsulin>;
}

export function AccountInsulinComponent(props: Props) {
  const { accountInsulin } = props
  const { openAccountInsulinModal } = useAccountInsulinModalContext()

  const onClick = useCallback(
    () => {
      openAccountInsulinModal(accountInsulin)
    },
    [
      accountInsulin,
      openAccountInsulinModal,
    ],
  )

  // TODO componentize like CGMMenuItem ?
  return (
    <li className={styles.listItem}>
      <div className={styles.listItemText}>
        <div className={styles.listItemTopRow}>
          <span className={styles.listItemName}>
            {accountInsulin.insulin.name}
          </span>
          <span className={styles.listItemForm}>
            {accountInsulin.form}
          </span>
        </div>
        <div className={styles.listItemDescription}>
          {accountInsulin.insulin.description}
        </div>
      </div>
      <IconButton
        className={styles.listItemSettingsButton}
        stylePreset="withoutBackground"
        onClick={onClick}
      >
        <GearOutlineIcon />
      </IconButton>
    </li>
  )
}
