import {
  CheckMarkIcon,
  Input,
  InputIconButton,
  InputWithIconButton,
} from "@tandem-mobile/react-components"
import classNames from "classnames"
import type {
  ChangeEventHandler,
  MouseEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from "react"
import {
  useCallback,
  useRef,
  useState,
} from "react"
import styles from "./FoodTitle.module.scss"
import { useFoodSearchContext } from "src/components/FoodSearchModal"
import { usePolyglot } from "src/contexts"
import type { FoodActivity } from "src/types"

interface Props {
  activity: FoodActivity;
  updateActivity: (a: FoodActivity) => void;
}

const id = "food-title-text-input"

/*
 * this component renders the text input for the food title and searches
 * food when the input blurs so that the search results are
 * pre-fetched
 */
export function FoodTitleInput(props: Props) {
  const {
    activity, updateActivity,
  } = props

  const polyglot = usePolyglot()

  const inputRef = useRef<HTMLInputElement>(null)
  const [
    isFocused,
    setIsFocused,
  ] = useState<boolean>(false)

  const { setSearchValue } = useFoodSearchContext()

  // clicking the checkmark will unfocus the input
  const onDone: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      // TODO
    },
    [inputRef],
  )

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const title = e.target.value

      updateActivity({
        ...activity,
        title,
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  const onEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === "Enter") {
        inputRef.current?.blur()
      }
    },
    [inputRef],
  )

  const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    () => {
      setSearchValue(activity.title ?? "")
      setIsFocused(false)
    },
    [
      activity.title,
      setIsFocused,
      setSearchValue,
    ],
  )

  const onFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    () => {
      setIsFocused(true)
    },
    [setIsFocused],
  )

  const doneButtonClassName = classNames({
    [styles.hiddenDone]: !isFocused,
  })

  return (
    <InputWithIconButton className={styles.input}>
      <Input
        ref={inputRef}
        id={id}
        value={activity.title}
        onChange={onChange}
        placeholder={polyglot.t("components.entry_modal.food.title.placeholder")}
        type="text"
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onEnter}
      />
      <InputIconButton
        className={doneButtonClassName}
        placementPreset="end"
        onClick={onDone}
      >
        <CheckMarkIcon />
      </InputIconButton>
    </InputWithIconButton>
  )
}
