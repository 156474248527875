import {
  ModalBody,
  ModalCloseX,
  ModalHeading,
} from "@tandem-mobile/react-components"
import { useEffect } from "react"

import { ContextMenu } from "../ContextMenu"
import { DateTimeInput } from "../DateTimeInput"
import { DurationInput } from "../DurationInput"
import { ImageThumbnail } from "../ImageThumbnail"
import { NoteInput } from "../NoteInput"
import { SubEntryFormButtons } from "../SubEntryFormButtons"
import { UploadImage } from "../UploadImage"

import { defaultExerciseActivity } from "./defaultExerciseActivity"
import { ExerciseIntensityRadioButton } from "./ExerciseIntensityRadioButton"
import { ExerciseTypeSelect } from "./ExerciseTypeSelect"
import {
  ActivityIcon,
  ActivityName,
} from "src/components/Activity"
import styles from "src/styles/entryModal.module.scss"
import type {
  EditableSubEntry,
  EditableUserEntry,
  ExerciseActivity,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  updateActivity: (a: ExerciseActivity) => void;
  currentSubEntry: EditableSubEntry<ActivityType.Exercise>;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Exercise>>) => void;
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

export function ExerciseForm(props: Props) {
  const {
    updateActivity,
    currentSubEntry,
    updateSubEntry,
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const {
    exercise: activity = defaultExerciseActivity,
  } = currentSubEntry

  // initialize the activity to the default if it isn't defined
  useEffect(
    () => {
      if (!currentSubEntry.exercise) {
        updateActivity(defaultExerciseActivity)
      }
    },
    [
      currentSubEntry.exercise,
      updateActivity,
    ],
  )

  return (
    <>
      <ModalHeading>
        <div className={styles.headingContent}>
          <ActivityIcon
            activityType={ActivityType.Exercise}
            className={styles.activityIcon}
          />
          <ActivityName activityType={ActivityType.Exercise} />
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
        </div>
        <ContextMenu
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
        <ModalCloseX />
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        <DurationInput
          currentSubEntry={currentSubEntry}
          updateSubEntry={updateSubEntry}
        />
        <ExerciseTypeSelect
          activity={activity}
          updateActivity={updateActivity}
        />
        <ExerciseIntensityRadioButton
          activity={activity}
          updateActivity={updateActivity}
        />
        <div className={styles.row}>
          <NoteInput
            currentSubEntry={currentSubEntry}
            updateSubEntry={updateSubEntry}
          />
          <UploadImage updateSubEntry={updateSubEntry} />
        </div>
        <ImageThumbnail
          currentSubEntry={currentSubEntry}
          updateSubEntry={updateSubEntry}
        />
        <SubEntryFormButtons
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
      </ModalBody>
    </>
  )
}
