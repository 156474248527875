import {
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandem-mobile/react-components"
import styles from "./FoodServingView.module.scss"
import { usePolyglot } from "src/contexts"
import type { FoodSearchServing } from "src/types"

import { round } from "src/utils"

interface Props {
  foodSearchServing: FoodSearchServing;
  numberOfServings: number;
}

const polyglotPrefix = "components.entry_modal.food."

/*
 * this component renders carbs, protein, fat, sugar, fiber, and calories info
 * for a food serving
 */
export function FoodServingView(props: Props) {
  const {
    foodSearchServing,
    numberOfServings,
  } = props

  const polyglot = usePolyglot()

  return (
    <div className={styles.nutritionInfo}>
      {foodSearchServing.carbs !== undefined && (
        <SliderValue sizePreset="small">
          <SliderValueInput
            className={styles.nutritionValue}
            value={round(
              numberOfServings * Number(foodSearchServing.carbs),
              1,
            )}
            readOnly
          />
          <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}carbs.label_unit`)}>
            <SliderValueLabel>
              {polyglot.t(`${polyglotPrefix}carbs.label_detail`)}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      )}
      {foodSearchServing.protein !== undefined && (
        <SliderValue sizePreset="small">
          <SliderValueInput
            className={styles.nutritionValue}
            value={round(
              numberOfServings * Number(foodSearchServing.protein),
              1,
            )}
            readOnly
          />
          <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}protein.label_unit`)}>
            <SliderValueLabel>
              {polyglot.t(`${polyglotPrefix}protein.label_detail`)}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      )}
      {foodSearchServing.fat !== undefined && (
        <SliderValue sizePreset="small">
          <SliderValueInput
            className={styles.nutritionValue}
            value={round(
              numberOfServings * Number(foodSearchServing.fat),
              1,
            )}
            readOnly
          />
          <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}fat.label_unit`)}>
            <SliderValueLabel>
              {polyglot.t(`${polyglotPrefix}fat.label_detail`)}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      )}
      {foodSearchServing.sugar !== undefined && (
        <SliderValue sizePreset="small">
          <SliderValueInput
            className={styles.nutritionValue}
            value={round(
              numberOfServings * Number(foodSearchServing.sugar),
              1,
            )}
            readOnly
          />
          <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}sugar.label_unit`)}>
            <SliderValueLabel>
              {polyglot.t(`${polyglotPrefix}sugar.label_detail`)}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      )}
      {foodSearchServing.fiber !== undefined && (
        <SliderValue sizePreset="small">
          <SliderValueInput
            className={styles.nutritionValue}
            value={round(
              numberOfServings * Number(foodSearchServing.fiber),
              1,
            )}
            readOnly
          />
          <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}fiber.label_unit`)}>
            <SliderValueLabel>
              {polyglot.t(`${polyglotPrefix}fiber.label_detail`)}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      )}
      {foodSearchServing.calories !== undefined && (
        <SliderValue sizePreset="small">
          <SliderValueInput
            className={styles.nutritionValue}
            value={round(numberOfServings * Number(foodSearchServing.calories))}
            readOnly
          />
          <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}calories.label_unit`)}>
            <SliderValueLabel>
              {polyglot.t(`${polyglotPrefix}calories.label_detail`)}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      )}
    </div>
  )
}
